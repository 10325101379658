import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios';
import useAuth from 'src/hooks/useAuth';
import { format as formatDate, isDate } from 'date-fns';
import { enCA, fr } from 'date-fns/locale';

import { useTranslation, initReactI18next } from 'react-i18next';

export const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
    const [translationData, setTranslationData] = useState('');
    const [originalTranslatedData, setOriginalTranslatedData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isTranslationSaved, setIsTranslationSaved] = useState(false);

    const [reportDealershipData, setReportDealershipData] = useState([]);

    const [languageData, setLanguageData] = useState([]);

    const translatedKeysRef = useRef([]);

    const { t, i18n } = useTranslation();

    // date formating
    const locales = { enCA, fr };

    const { user } = useAuth();

    let generic_translation = user.translations.default;

    const get_language = i18n.language;
    let isEnglish = get_language === 'en' ? true : false;

    const getTranslationData = useCallback(async () => {
        let url;
        url = `api/admin/translate`;
        try {
            await axios
                .get(
                    `${url}`
                )
                .then((response) => {
                    setTranslationData(response?.data.translations);
                    setOriginalTranslatedData(response?.data.translations);
                    setLoading(false);
                });
        } catch (err) {
            console.log('~ err', err);
        }
    }, []);


    const getLangData = useCallback(async () => {
        try {
            let translations;
            translations = generic_translation;
            let language = i18n.language;

            i18n.use(initReactI18next)
                .init({
                    resources: {
                        [language]: {
                            translation: translations
                        }
                    },
                    lng: language,
                    fallbackLng: language,
                    // interpolation: {
                    //     escapeValue: false
                    // }
                    interpolation: {
                        escapeValue: false,
                        format: (value, format, lng) => {
                            if (isDate(value)) {
                                const locale = locales[lng];
                                if (format === 'long') return formatDate(value, 'PP', { locale });
                                return formatDate(value, format, { locale });
                            }

                            return value;
                        }
                    }
                });

            setLanguageData(translations);

            setLoading(false);
        } catch (err) {
            console.log('~ err', err);
            //   window.location.href = '/account/login';
        }
    }, [isEnglish]);

    const getDealershipReportUsingLocation = async (location_id) => {
        try {
            axios.get(`/api/dashboard/dealership/${location_id}`)
                .then(response => {
                    const responseData = response?.data;
                    setReportDealershipData(responseData);
                    return responseData?.data;
                })
                .catch(error => {
                    console.error('Request Error:', error.message);
                });
        } catch (err) {
            console.log('~ err', err);
            // window.location.href = '/account/login';
        }
    }

    const getAllDealershipReport = useCallback(async () => {
        axios.get(`/api/dashboard/dealership`)
            .then(response => {
                const responseData = response?.data;
                getDealershipReportUsingLocation(responseData?.active_location?.id);
            })
            .catch(error => {
                console.error('Request Error:', error.message);
            });
    }, []);

    useEffect(() => {
        getTranslationData();
        getLangData();
    }, [getLangData, getTranslationData]);


    if (isLoading) {
        return null;
    }
    /*  
        Name: saveTranslation,
        Purpose: save translation text
        Type: Post
    */
    const saveTranslation = async (id, enteredText) => {
        let data = {
            target: enteredText
        }
        let url = `api/translate/${id}`;
        try {
            await axios
                .post(
                    `${url}`,
                    data
                )
                .then((response) => {
                    // getTranslationData();
                    setIsTranslationSaved(true);

                });
        } catch (err) {
            console.log('~ err', err);
        }

    }

    /* 
        Handle mission translation while loading page.. 
    */
    const saveMissingTranslation = async (key) => {
        if (!translatedKeysRef.current.includes(key)) {
            translatedKeysRef.current.push(key);
            let data = {
                source: key,
                folder: 'default'
            };
            try {
                let response = await axios.post(`api/translate`, data);
                return response.status;
            } catch (err) {
                console.log('~ err', err);
            }
        }
    };

    // Wrap this function around the text that we want to translate  .....
    const textTranslation = (key) => {
        let translations = languageData ? [languageData] : [];
        const translation = t(key, { defaultValue: key });
        if (translation && translation === key && translations && !translations.find((data) => data[key])) {
            saveMissingTranslation(key);
            return key;
        } else {
            return t(key);
        }
    };

    /*  
        Name: deleteTranslation,
        Purpose: delete english/french text
        Type: Delete
    */
    const deleteTranslation = async (id) => {
        console.log('delete translation', id);

        let url;
        url = `api/translate/${id}`;
        try {
            await axios
                .delete(
                    `${url}`
                )
                .then((response) => {
                    getTranslationData();
                    setIsDeleted(true);
                    console.log('Text Deleted: ', response);
                });
        } catch (err) {
            console.log('~ err', err);
        }
    }


    return (
        <TranslationContext.Provider value={{
            languageData,
            translationData,
            originalTranslatedData,
            setTranslationData,
            isLoading: isLoading,
            saveTranslation,
            deleteTranslation,
            isDeleted,
            setIsDeleted,
            isTranslationSaved,
            setIsTranslationSaved,
            saveMissingTranslation,
            textTranslation,
            reportDealershipData,
            getAllDealershipReport,
            getDealershipReportUsingLocation
        }} >
            {children}
        </TranslationContext.Provider>
    )
}