import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// import jwt from 'jsonwebtoken';
import { verify } from 'jsonwebtoken';
import { JWT_SECRET } from 'src/utils/jwt';
import useAuth from 'src/hooks/useAuth';
// import Login from 'src/content/pages/Auth/Login/Cover';
import LoginBasic from 'src/content/pages/Auth/Login/Basic';

const Authenticated = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  let myToken = localStorage.getItem('accessToken');
  // console.log(
  //   'checkToken=====>',
  //   myToken !== null ? jwt.verify(myToken, JWT_SECRET) : null
  // );
  function checkTokenExpired() {
    try {
      return verify(myToken, JWT_SECRET);
    } catch (err) {
      if (err) {
        console.log('Error in authenticated: ', err);
        return <LoginBasic />;
      }
      throw err;
    }
  }
  checkTokenExpired();
  if (myToken === null) {
    return <LoginBasic />;
  }
  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <LoginBasic />;
    // return <Login />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
