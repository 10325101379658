import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const ListUser = Loader(lazy(() => import('src/content/ManageUser/ListUser')));
const EditUser = Loader(lazy(() => import('src/content/ManageUser/EditUser')));
const CreateUser = Loader(
  lazy(() => import('src/content/ManageUser/CreateUser'))
);
const PositionAccess = Loader(
  lazy(() => import('src/content/ManageUser/PositionAcces'))
);
const PositionAccessEdit = Loader(
  lazy(() => import('src/content/ManageUser/PositionAccessEdit'))
);
const PositionAccessAdd = Loader(
  lazy(() => import('src/content/ManageUser/PositionAccessAdd'))
);
const managementUser = [
  {
    path: '/',
    element: <Navigate to="user" replace />
  },
  {
    path: 'user',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <ListUser />
      },
      {
        path: 'create',
        element: <CreateUser />
      },
      {
        path: 'position-access/:userId',
        element: <PositionAccess />
      },
      {
        path: 'position-access/edit/:userId/:positionId/:locationId',
        element: <PositionAccessEdit />
      },
      {
        path: 'position-access/add/:userId',
        element: <PositionAccessAdd />
      },

      {
        path: 'update/:userId',
        element: <EditUser />
      }
    ]
  }
];

export default managementUser;
