import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import StudentRoutes from './student';
import leaderboardRoutes from './leaderboard';
import aboutRoute from './about';
import noData from './noData';
import documentationRoutes from './documentation';
import accountRoutes from './account';
import baseRoutes from './base';
import managementRoutes from './managementUser';
import companyTileReportRoutes from './report';
import adminRoutes from './admin';
import GMRoutes from './generalManager';
import DownloadReportRoutes from './downloadReportRoutes';
import gapsReportRoutes from './gapsReport';
import newDashboardsRoutes from './newDashboard';

const router = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: 'docs',
    element: <DocsLayout />,
    children: documentationRoutes
  },
  {
    path: '/',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="ndashboard" replace />
      },
      // {
      //   path: 'dashboard',
      //   children: dashboardsRoutes
      // },
      {
        path: 'ndashboard',
        children: newDashboardsRoutes
      },
      {
        path: 'student',
        children: StudentRoutes
      },
      {
        path: 'download',
        children: DownloadReportRoutes
      },
      {
        path: 'gm-dashboard',
        children: GMRoutes
      },
      {
        path: 'reports',
        children: companyTileReportRoutes
      },
      {
        path: 'gaps-report',
        children: gapsReportRoutes
      },
      {
        path: 'admin',
        children: adminRoutes
      },
      // {
      //   path: 'course',
      //   children: courses
      // },
      // {
      //   path: 'dashboard',
      //   children: dashboardsRoutes
      // },
      // {
      //   path: 'dashboard',
      //   children: dashboardIDRoutes
      // },
      {
        path: 'leaderboard',
        children: leaderboardRoutes
      },
      {
        path: 'about',
        children: aboutRoute
      },
      {
        path: 'no_data',
        children: noData
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  }
];

export default router;
