import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  let selected_lnaguage = localStorage.getItem('lng');
  const handleTerms = () => {
    navigate('/about/terms');
  };
  const handlePrivacy = () => {
    navigate('/about/privacy');
  };
  const handleDashboard = () => {
    navigate('/');
  };

  return (
    <div>
      <div
        style={{
          position: 'fixed',
          bottom: '0',
          width: '100%',
          height: '20px',
          zIndex: '100',
          backgroundColor: '#4D4D4D',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',

            justifyContent: 'center'
          }}
        >
          <button
            type="button"
            style={{
              // width: '200px',
              color: 'white',
              margin: '5px',
              border: 'none',
              backgroundColor: '#4D4D4D',
              cursor: 'pointer',
              outline: 'none',
              fontSize: '12px'
            }}
            onClick={handleDashboard}
          >
            {t('Training Report Centre')}
          </button>
          <button
            type="button"
            style={{
              // width: '200px',
              color: 'white',
              margin: '5px',
              border: 'none',
              backgroundColor: '#4D4D4D',
              cursor: 'pointer',
              outline: 'none',
              fontSize: '12px'
            }}
            onClick={handlePrivacy}
          >
            {selected_lnaguage === 'en' ? 'Privacy Policy' : 'Politique de confidentialité'}
          </button>
          <button
            type="button"
            style={{
              // width: '200px',
              color: 'white',
              margin: '5px',
              border: 'none',
              backgroundColor: '#4D4D4D',
              cursor: 'pointer',
              outline: 'none',
              fontSize: '12px'
            }}
            onClick={handleTerms}
          >
            {selected_lnaguage === 'en' ? 'Terms of Service' : "Conditions d'utilisation"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Footer;
