import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);
const GMDashboardContainer = Loader(lazy(() => import('src/content/GeneralManager/Dashboard/GMIndex')));
const ReportBreakdownIndex = Loader(lazy(() => import('src/content/GeneralManager/TrainingReportBreakdown/ReportBreakdownIndex')));

const GMRoutes = [
    {
        path: '/',
        element: <GMDashboardContainer />
    },
    {
        path: '/report-break-down/:loc_id/:id',
        element: <ReportBreakdownIndex />
    },
];

export default GMRoutes;
