import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);
const DealershipReport = Loader(lazy(() => import('src/content/Dashboard/DealershipReport')));
const QDPReport = Loader(lazy(() => import('src/content/Dashboard/QDPReport')));
const DepartmentReport = Loader(lazy(() => import('src/content/Dashboard/DepartmentReport/index')));
const StudentReport = Loader(lazy(() => import('src/content/Dashboard/StudentReport/index')));
const SavedReport = Loader(lazy(() => import('src/content/NewDashboard/SavedReport')));
// const ReportOptions = Loader(lazy(() => import('src/content/Dashboard/ReportOptions')));
const GenerateReport = Loader(lazy(() => import('src/content/GenerateReport/Index')));

const companyTileReportRoutes = [
    {
        path: '/:id',
        element: <DealershipReport />
    },
    {
        path: '/:id/qdp',
        element: <QDPReport />
    },
    {
        path: '/generate-report/:id',
        element: <GenerateReport />
    },
    {
        path: '/:id/department',
        element: <DepartmentReport />
    },
    {
        path: '/:id/student',
        element: <StudentReport />
    },
    {
        path: '/saved/:saved_report_id',
        element: <SavedReport />
    }

];

export default companyTileReportRoutes;
