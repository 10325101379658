import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Leaderboard = Loader(lazy(() => import('src/content/Leaderboard')));

const leaderboardRoutes = [
  {
    path: '/',
    element: <Leaderboard />
  }
];

export default leaderboardRoutes;
