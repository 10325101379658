// import { Link as RouterLink } from 'react-router-dom';
// import {
//   Box,
//   Card,
//   Link,
//   Tooltip,
//   Typography,
//   Container,
//   Alert,
//   styled
// } from '@mui/material';

// import { useTranslation } from 'react-i18next';
// import Logo from 'src/components/LogoSign';

// import { Helmet } from 'react-helmet-async';
// import useAuth from 'src/hooks/useAuth';
// import Auth0Login from '../LoginAuth0';
// import FirebaseAuthLogin from '../LoginFirebaseAuth';
// import JWTLogin from '../LoginJWT';
// import AmplifyLogin from '../LoginAmplify';

// const icons = {
//   Auth0: '/static/images/logo/auth0.svg',
//   FirebaseAuth: '/static/images/logo/firebase.svg',
//   JWT: '/static/images/logo/jwt.svg',
//   Amplify: '/static/images/logo/amplify.svg'
// };

// const CardImg = styled(Card)(
//   ({ theme }) => `
//     width: 90px;
//     height: 80px;
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     background: ${theme.colors.alpha.white[100]};
//     margin: 0 ${theme.spacing(1)};
//     border: 1px solid ${theme.colors.alpha.black[10]};
//     transition: ${theme.transitions.create(['all'])};

//     &:hover {
//       border-color: ${theme.colors.primary.main};
//     }
// `
// );

// const BottomWrapper = styled(Box)(
//   ({ theme }) => `
//     padding: ${theme.spacing(3)};
//     display: flex;
//     align-items: center;
//     justify-content: center;
// `
// );

// const MainContent = styled(Box)(
//   () => `
//     height: 100%;
//     display: flex;
//     flex: 1;
//     flex-direction: column;
// `
// );

// const TopWrapper = styled(Box)(
//   () => `
//   display: flex;
//   width: 100%;
//   flex: 1;
//   padding: 20px;
// `
// );

// function LoginBasic() {
//   const { method } = useAuth();
//   const { t } = useTranslation();

//   return (
//     <>
//       <Helmet>
//         <title>Login - Basic</title>
//       </Helmet>
//       <MainContent>
//         <TopWrapper>
//           <Container maxWidth="sm">
//             <Logo />
//             <Card
//               sx={{
//                 mt: 3,
//                 px: 4,
//                 pt: 5,
//                 pb: 3
//               }}
//             >
//               <Box>
//                 <Typography
//                   variant="h2"
//                   sx={{
//                     mb: 1
//                   }}
//                 >
//                   {t('Sign in')}
//                 </Typography>
//                 <Typography
//                   variant="h4"
//                   color="text.secondary"
//                   fontWeight="normal"
//                   sx={{
//                     mb: 3
//                   }}
//                 >
//                   {t('Fill in the fields below to sign into your account.')}
//                 </Typography>
//               </Box>
//               {method === 'Auth0' && <Auth0Login />}
//               {method === 'FirebaseAuth' && <FirebaseAuthLogin />}
//               {method === 'JWT' && <JWTLogin />}
//               {method === 'Amplify' && <AmplifyLogin />}
//               <Box my={4}>
//                 <Typography
//                   component="span"
//                   variant="subtitle2"
//                   color="text.primary"
//                   fontWeight="bold"
//                 >
//                   {t('Don’t have an account, yet?')}
//                 </Typography>{' '}
//                 <Link component={RouterLink} to="/account/register-basic">
//                   <b>Sign up here</b>
//                 </Link>
//               </Box>
//               {method !== 'Auth0' && (
//                 <Tooltip
//                   title={t('Used only for the live preview demonstration !')}
//                 >
//                   <Alert severity="warning">
//                     Use <b>demo@example.com</b> and password <b>TokyoPass1@</b>
//                   </Alert>
//                 </Tooltip>
//               )}
//             </Card>
//             <BottomWrapper>
//               <Tooltip arrow placement="top" title="Auth0">
//                 <CardImg>
//                   <img height={50} alt="Auth0" src={icons.Auth0} />
//                 </CardImg>
//               </Tooltip>
//               <Tooltip arrow placement="top" title="Firebase">
//                 <CardImg>
//                   <img height={50} alt="Firebase" src={icons.FirebaseAuth} />
//                 </CardImg>
//               </Tooltip>
//               <Tooltip arrow placement="top" title="JSON Web Token">
//                 <CardImg>
//                   <img height={50} alt="JSON Web Token" src={icons.JWT} />
//                 </CardImg>
//               </Tooltip>
//               <Tooltip arrow placement="top" title="Amplify">
//                 <CardImg>
//                   <img height={50} alt="Amplify" src={icons.Amplify} />
//                 </CardImg>
//               </Tooltip>
//             </BottomWrapper>

//             <Alert severity="error">
//               {t(
//                 'Learn how to switch between auth methods by reading the section we’ve prepared in the documentation.'
//               )}
//             </Alert>
//           </Container>
//         </TopWrapper>
//       </MainContent>
//     </>
//   );
// }

// export default LoginBasic;

// second way

import { Box, Card, Container, styled } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
// import Logo from 'src/components/LogoSign';

import { Helmet } from 'react-helmet-async';
// import useAuth from 'src/hooks/useAuth';
import { loginDetails } from '../../../../../config';
import hondaImg from './hondaImg.png';
import acuraImg from './acura.png';

// console.log('🚀 ~ loginDetails', loginDetails);
const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

function LoginBasic() {
  // const { method } = useAuth();
  const { t } = useTranslation();

  const handleClick = () => {
    window.location.href = loginDetails.loginUrl;
    //  window.location.href= 'https://dev.hondahub.ca/?r=saml2/idp/SSOService&spentityid=https://recognition-dev.hondahub.ca';
  };
  return (
    <>
      <Helmet>
        <title>Login - Basic</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">
            <Card
              sx={{
                mt: 25,
                px: 4,
                pt: 5,
                pb: 3
              }}
            >
              <Box style={{ textAlign: 'center' }}>
                {loginDetails.loginLabel.toLowerCase().includes('honda') && (
                  <img src={hondaImg} alt="hondImg" />
                )}
                {loginDetails.loginLabel.toLowerCase().includes('acura') && (
                  <img src={acuraImg} alt="acuraImg" />
                )}
              </Box>
              <Box style={{ marginTop: '20px' }}>
                <Button fullWidth variant="contained" onClick={handleClick}>
                  {t(`Login with ${loginDetails.loginLabel}`)}
                </Button>
              </Box>
            </Card>
          </Container>
        </TopWrapper>
      </MainContent>
    </>
  );
}

export default LoginBasic;
