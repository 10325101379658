const frJSON = {
  // side bar
  'About The Program': 'À propos du programme',
  Dashboard: 'Tableau de bord',
  Leaderboard: 'Tableau des meneurs',
  'Back To Honda Hub': 'Retour au portail \n Honda Hub',
  'Back To Acura Hub': 'Retour au portail \n Acura Hub',
  Language: 'Langue',
  English: 'Anglais',
  French: 'Français',
  'Log Out': 'Déconnexion',
  // at: 'chez',

  // date
  today: ' Aujourd’hui, nous sommes le {{ date, long }}',
  updateDate: 'Données mises à jour le  {{effectiveDate,long}}',
  asOf: 'À partir de {{effectiveDateLeaderboard,long}}',
  trainingDate: '{{val,long}}',
  updateAsOf: '{{updateAsOfDate,long}}',
  // dashboard
  'Welcome Back!': 'Bon retour!',
  DEALERSHIP: 'CONCESSION',
  Dealership: 'Concession',
  Dealerships: 'Concessionnaires',
  TARGET: 'OBJECTIF',
  POSITION: 'POSTES',
  Position: 'Postes',
  RANK: 'RANG',
  Rank: 'Rang',
  Group: 'Groupe',
  'Lowest Trending': 'Tendance la plus basse ',
  'Highest Trending': 'Tendance la plus élevée',
  Departments: 'Départements',
  Zones: 'Zones',
  'All Zones': 'Toutes les zones',
  'All Levels': 'Tous les niveaux',
  'Central Zone': 'Zone centrale',
  'Eastern Zone': 'Zone de l’Est',
  'Atlantic Zone': 'Zone de l’Atlantique',
  'Pacific Zone': 'Zone du Pacifique',
  'Prairie Zone': 'Zone des Prairies',
  Positions: 'Postes',
  'All Positions': 'Tous les postes',
  'Pre-Owned Sales Manager': 'Directeur des ventes de véhicules d’occasion',
  'General Sales Manager': 'Directeur général des ventes',
  'Sales Manager': 'Directeur des ventes',
  'Sales Person': 'Conseiller des ventes',
  'Used Car Sales Manager': 'Directeur des ventes de véhicules d’occasion',
  'Sales Manager (Honda)': 'Directeur des ventes (Honda)',
  'Sales Person (Honda)': 'Conseiller des ventes (Honda)',
  'Used Car Sales Manager (Honda)':
    'Directeur des ventes de véhicules d’occasion (Honda)',
  'Sales Manager (Acura)': 'Directeur des ventes (Acura)',
  'Sales Person (Acura)': 'Conseiller des ventes (Acura)',
  'Used Car Sales Manager (Acura)':
    'Directeur des ventes de véhicules d’occasion (Acura)',
  'QUICK SUMMARY': 'RÉSUMÉ RAPIDE',
  Support: 'Soutien',
  Master: 'Maître',
  'Not Qualified': 'Non admissible',
  'Sales Leader': 'Leader des ventes',
  'Sales Master': 'Maître des ventes',
  'Senior Sales Master': 'Maître des ventes principal',
  'Grand Master': 'Grand maître',
  'Sales Consultant': 'Conseiller des ventes',
  'Pre Owned Sales Manager': 'Directeur des ventes de véhicules d’occasion',
  'Dealer Principal': 'Concessionnaire en titre',
  'General Manager': 'Directeur général',
  Employees: 'Employés ',
  Sales: 'Ventes',
  'Sales (Honda)': 'Ventes (Honda)',
  'Sales (Acura)': 'Ventes (Acura)',
  'Sales Amendment (Honda)': 'Modification des objectives de ventes (Honda)',
  Training: 'Formation',
  'WORKING TOWARDS': 'EN COURS D’ATTEINTE',
  'CURRENT STATUS': 'ÉTAT ACTUEL',
  CRITERIA: 'CRITERES',
  ACTUAL: 'ACTUEL',
  HISTORY: 'HISTORIQUE',
  TITLE: 'TITRE',
  'DATE COMPLETED': 'DATE D’ACHÈVEMENT',
  'Back to Leaderboard': 'Retour au tableau des meneurs',
  'High Five': 'Haut la main',
  'Points Earned': 'Points mérités',
  'CXI Qualifier': 'Admissibilité au CXI',
  'Training Qualifier': 'Admissibilité pour la formation',
  'Dealer Rank': 'Rang du concessionnaire',
  Completed: 'Complété',
  Complete: 'Complété',
  Incomplete: 'Non complété',
  OSAT: 'Satisfaction globale',
  'Temp Level KPI’s': 'Indicateurs clés de performance de niveau moyen',
  'Temp Level ': 'Niveau moyen',
  KPI: 'Indicateur clé de performance',
  '*Sales data is one month behind':
    '*Les données sur les ventes sont basées sur le mois précédent',
  '** Financial data is up to two months behind':
    "** Les données financières peuvent remonter jusqu'à deux mois précédants",
  'Current Status': 'État actuel',
  "Current Status KPI's": 'Indicateurs clés de performance de l’état actuel',
  Recognition: 'Reconnaissance',
  'Privacy Policy': 'Politique de confidentialité',
  'Terms of Service': 'Terms de service',
  'This represents the target required to meet the next level.':
    'Cela représente l’objectif requis pour atteindre le niveau suivant.',
  'E-Mail Accuracy of 90%': 'Exactitude des adresses de courriel de 90 %',
  'E-Mail Accuracy of {{target}} %':
    'Exactitude des adresses de courriel de {{target}} %',
  'OSAT Benchmark': 'Point de référence de la satisfaction générale',
  '{{target}}% of Sales People must have completed their online training.':
    '{{target}}% des conseillers des ventes doivent avoir complété leur formation en ligne',
  "{{target}}'s Individual CXI Score": 'Résultat CXI individuel de {{target}}',
  "{{target}}'s Training Courses": 'Cours de formation de {{target}}',
  '100 % of Sales People completed online training ':
    '100 % des conseillers aux ventes ont suivi une formation en ligne',
  'Honda Certified Used vehicle registrations – Ranking':
    'Enregistrements des véhicules d’occasion certifiés Honda - Rang',
  'Honda Certified Used vehicle registrations - Ranking':
    'Enregistrements des véhicules d’occasion certifiés Honda - Rang',
  'No Data': 'Aucune donnée',
  "Your position does not participate in a Recognition Program and/or you don't oversee anyone that is a participant in a Recognition Program":
    'Votre poste ne participe pas à un programme de reconnaissance et/ou vous ne supervisez personne qui participe à un programme de reconnaissance.',
  'Dealership Categories': 'Catégories de concessionnaires',
  'Completion of the 2022 HCFI Innovate Excellence F&I Certification Program Training':
    'Achèvement de la formation «programme de certification F&I Innover l’excellence HCFI 2022»',
  'CXI: The Overall Satisfaction score for the Business Office (by Business Managers as well as by dealership total)':
    'Indice de l’expérience client (CXI) : Le résultat global de satisfaction du bureau commercial (par directeur commercial et total de la concession)',
  'Total Penetration (Retail and Lease combined)':
    'Total du taux de pénétration (volume combiné des contrats de vente au détail et de location)',
  'Acura Plus Extended Warranty Sales':
    'Ventes de la garantie prolongée Acura Plus',
  'ACPOV Plus Upgrades Sales Penetration':
    'Ventes de produits Plus surclassés pour les VOCA',
  'Lease-Guard Sales': 'Ventes de la Protection pour location-bail',
  'Acura Precision Sales': 'Ventes d’Acura Précision',
  'Acura Plus Total PPD KPI (Products per deal)':
    'Total produits Acura Plus par transaction– Indicateur de performance PPD',
  'AFS ACPOV Finance Penetration':
    'Taux de pénétration du financement des VOCA par les SFA',
  'Honda Plus Extended Warranty Sales':
    'Ventes de la garantie prolongée Honda Plus',
  'HCUV Plus Upgrades Sales Penetration':
    'Ventes de produits Honda Plus surclassés pour les VOCH',
  'Honda Advantage Sales': 'Ventes de Honda Avantage',
  'Honda Plus Total PPD KPI (Products per deal)':
    'Total produits Honda Plus par transaction– Indicateur de performance PPD',
  'HFS HCUV Finance': 'Pénétration du financement des VOCH par les SFH',
  'N/A': 'S/O',
  '1 point per 1%': '1 point par taux de pénétration de 1 %',
  '0.5 point per 1%': '0.5 point par taux de pénétration de 1 %',
  'See Bulletin': 'Voir le bulletin',
  '{{target}} points': '{{target}} points',
  'Training data is static and will be updated monthly.':
    'Les données pour la formation sont statiques et seront mises à jour à tous les mois',
  'Training: Core': 'Formation: Connaissances principales',
  'Training: Product Knowledge': 'Formation: Connaissance des produits',
  'Training: Professional Development':
    'Formation: Développement Professionnel',
  VOC: 'Voix du client',
  'VOC Service CXI (OSAT, Loyalty, Recommend)':
    '(CXI) pour le Service (satisfaction globale, fidélisation, recommandation)',
  // widgets
  Certified: 'Certifié',
  'Training Courses': 'Cours de formation',
  'Certified Professional Certification Achieved': 'Professionnel Certifié ',
  'Sell {{target}} Vehicles': 'Vendre {{target}} véhicules',
  'Sell 60 Vehicles': 'Vendre 60 véhicules',
  'Achieve {{target}} of OSAT':
    "Atteindre {{target}} d'un pourcentage de satisfaction globale OSAT",
  'Achieve RSO of 100% or above Zone Average':
    'Atteindre un objectif de vente au détail de 100 % ou au-dessus de la moyenne de la zone',
  'Achieve CXI of {{target}}': 'Atteindre un CXI de {{target}}',
  'E-Mail Accuracy of {{target}}':
    'Exactitude des adresses de courriel {{target}}',
  'Sales Volume': 'Volume de ventes',
  'Dealership OSAT by Salesperson':
    'Satisfaction générale de la concession par conseiller des ventes',
  'Sales Criteria (% of RSO)': 'Criteres de ventes % du RSO',
  'Achieve dealership Sales CXI of 95%':
    'Doit attiendre au moins 95% pour le CXI des Ventes',
  'Concierge Phase Attainment of 80%':
    'Doit atteindre un minimum de 80% sur la rapport Acura Concierge',
  'Concierge Phase Attainment of 82%':
    'Doit atteindre un minimum de 82% sur la rapport Acura Concierge',
  Diamond: 'Diamant',
  Platinum: 'Platine',
  Gold: 'Or',
  'Guild Member': 'Membre de la Guilde',
  'Pre-Owned Sales Volume': 'Volume de ventes de véhicules d’occasion',
  'Dealer Sales CXI': 'CXI des ventes du concessionnaire',
  'Individual CXI Score': 'Résultat CXI individuel',
  'Dealership Points Earned': 'Points mérités du concessionnaire',
  'Dealership Rank': 'Rang du concessionnaire',
  'Individual Training Completion': 'Achèvement de la formation individuel',
  'MONTHLY STATUS': 'Statut mensuel',
  'FINAL RESULTS': 'RÉSULTATS FINAUX',
  'Final Results': 'Résultats finaux',
  'QUALIFYING REQUIREMENTS': "Les exigences d'admissibilité",
  MEASUREMENT: 'Mesure',
  'MAXIMUM POINTS': 'Maximum de points',
  'POINTS EARNED': 'Points gagnés',

  // leaderboard
  'Welcome to the Leaderboard!': 'Bienvenue au tableau des meneurs!',
  'Review the data from the beginning of the year to today.':
    'Passez en revue les données du début de l’année à aujourd’hui.',
  'Achievement Levels': 'Niveaux d’accomplissement',
  'Achievement Level': 'Niveaux d’accomplissement',
  Author: 'Auteur',
  'All levels': 'Tous les niveaux  ',
  'Rows per page :': 'Rangées par page :',
  Print: 'Imprimer',
  YES: 'OUI',
  NO: 'NON',
  'TRAINING CRITERIA ATTAINED (YES/NO)':
    'Atteinte du critère de formation (Oui/Non)',
  'TOTAL VEHICLE SALES': 'Ventes totales de véhicules',
  'SALES SURVEY/SALES RATIO (MUST BE >/= 15%) ':
    'Ratio sondage sur les ventes/ventes (doit être >/= 15 %)',
  'TOTAL VEHICLE SALES CRITERIA ATTAINED (YES/NO)':
    'Atteinte du critère de ventes totales de véhicules (Oui/Non)',
  'TEMP LEVEL': 'Niveau temporaire',
  'Temp Levels': 'Niveau temporaire',
  'SALES LEADER GAP': 'Leader des ventes Écart',
  'SALES MASTER': 'Maître des ventes',
  'SALES MASTER GAP': 'Maître des ventes Écart',
  'SENIOR SALES MASTER': 'Maître des ventes senior',
  'SENIOR SALES MASTER GAP': 'Maître des ventes senior Écart',
  'GRAND MASTER': 'Grand maître',
  'GRAND MASTER GAP': 'Grand maître Écart',
  'F&I Manager': 'Directeur du bureau commercial',
  'F&I Manager (Honda)': 'Directeur du bureau commercial (Honda)',
  'F&I Manager (Acura)': 'Directeur du bureau commercial (Acura)',
  'Service Manager': 'Directeur du service',
  'Service Manager (Honda)': 'Directeur du service (Honda)',
  'Service Manager (Acura)': 'Directeur du service (Acura)',
  'Parts Manager': 'Directeur des pièces',
  'Parts Manager (Honda)': 'Directeur des pièces (Honda)',
  'Parts Manager (Acura)': 'Directeur des pièces (Acura)',
  'Parts Counter Person': 'Préposé au comptoir des pièces',
  'Parts Counter Person (Honda)': 'Préposé au comptoir des pièces (Honda)',
  'Parts Counter Person (Acura)': 'Préposé au comptoir des pièces (Acura)',
  'Service Advisor': 'Conseiller au service',
  'Service Advisor (Honda)': 'Conseiller au service (Honda)',
  'Service Advisor (Acura)': 'Conseiller au service (Acura)',
  'Fixed Operations Manager': 'Directeur des opérations fixes',
  'Fixed Operations Manager (Honda)': 'Directeur des opérations fixes (Honda)',
  'Fixed Operations Manager (Acura)': 'Directeur des opérations fixes (Acura)',
  Technician: 'Technicien',
  'Technician (Honda)': 'Technicien (Honda)',
  'Technician (Acura)': 'Technicien (Acura)',
  'General Repair': 'Réparations générales',
  'Advance Repair': 'Réparations avancées',
  'Advanced Repair': 'Réparations avancées',
  'All Temp Levels': 'Tous les niveaux temporaire',
  'SALES SURVEY / NEW VEHICLE SALES RATIO CRITERIA ATTAINED (YES/NO)':
    'Atteinte du critère de ratio sondage sur les ventes/ventes de véhicules neufs (Oui/Non)',
  'ALL SPS IN DEALERSHIP > 1 YR CERTIFIED PROFESSIONAL (YES/NO)':
    'Tous les CV de la concession > 1 an comme professionnel certifié (oui/non)',
  'DEALER SALES CXI (MUST BE >/= 95%) ':
    'CXI des ventes du concessionnaire (doit être >/= 95 %) ',
  'DEALER SALES CXI CRITERIA ATTAINED (YES/NO)':
    'Critère CXI des ventes du concessionnaire atteint (oui/non)',
  '% OF ACURA CPO VEHICLE SALES PENETRATION (REQUIRED 60% MINIMUM)':
    '% de pénétration de ventes de VOC Acura (minimum 60 % requis)',
  '% OF ACURA CPO VEHICLE SALES PENETRATION (REQUIRED 60% MINIMUM) CRITERIA ATTAINED (YES/NO)':
    '% de pénétration de ventes de VOC Acura (minimum 60 % requis), critère atteint (oui/non)',
  'MINIMUM RDR VS. FS% (MIN. ATTAINMENT = 90%)':
    '% MINIMUM ENREGISTRÉ VS ÉTAT FINANCIER (atteinte min. = 90 %)',
  'Sell 60 vehicles': 'Vendre 60 véhicules',
  'All Groups': 'Tous les groupes',
  'All Dealerships': 'Toutes les concessions',
  NAME: 'NOM',
  GROUP: 'GROUPE',
  ACHIEVEMENT: 'ACCOMPLISSEMENT',
  Achievement: 'Accomplissement',
  'TOTAL SALES': 'TOTAL DES VENTES',
  '% OF CERTIFIED VS ELIGIBLE CERTIFIED UNITS (CERTIFIED & NON-CERTIFIED)':
    '% D’UNITÉS CERTIFIÉES PAR RAPPORT AUX UNITÉS CERTIFIÉES ADMISSIBLES (CERTIFIÉES ET NON CERTIFIÉES)',
  '% OF CERTIFIED VS ELIGIBLE CERTIFIED UNITS (CERTIFIED & NON-CERTIFIED) ATTAINED':
    '% D’UNITÉS CERTIFIÉES PAR RAPPORT AUX UNITÉS CERTIFIÉES ADMISSIBLES (CERTIFIÉES ET NON CERTIFIÉES) ATTEINT',
  'TOTAL COURSES COMPLETED': 'TOTAL DES COURS RÉUSSIS',
  'TRAINING CRITERIA ATTAINED': 'CRITÈRES DE FORMATION ATTEINTS',
  'SALES CXI BY DEALER': 'RÉSULTAT CXI VENTES PAR CONCESSIONNAIRE',
  'ZONE GROUP SALES CXI BY DEALER':
    'RÉSULTAT CXI VENTES DU GROUPE DE LA ZONE PAR CONCESSIONNAIRE',
  'ABOVE DEALER ZONE GROUP SALES CXI ATTAINED':
    'RÉSULTAT CXI VENTES SUPÉRIEUR AU GROUPE DE LA ZONE DU CONCESSIONNAIRE ATTEINT',
  'DEALER RSO%': '% DE L’OBJECTIF DE VENTE AU DÉTAIL DU CONCESSIONNAIRE',
  'ZONE GROUP RSO% AVERAGE':
    'MOYENNE DU % DE L’OBJECTIF DE VENTE AU DÉTAIL DU GROUPE DE LA ZONE',
  'RSO=100% OR ABOVE ZONE GROUP AVERAGE':
    'OBJECTIF DE VENTE AU DÉTAIL DE 100 % OU SUPÉRIEUR À LA MOYENNE DU GROUPE DE LA ZONE',
  '% EMAIL ACCURACY': '% D’EXACTITUDE DES COURRIELS',
  '% EMAIL ACCURACY ATTAINED': '% D’EXACTITUDE DES COURRIELS ATTEINT',
  '% OF SALES PEOPLE COMPLETED ONLINE TRAINING':
    '% DE CONSEILLERS AUX VENTES AYANT RÉUSSI UNE FORMATION EN LIGNE',
  '% OF SALES PEOPLE COMPLETED ONLINE TRAINING ATTAINED':
    '% DE CONSEILLERS AUX VENTES AYANT RÉUSSI UNE FORMATION EN LIGNE ATTEINT',
  'TOTAL NEW CAR SALES': 'TOTAL DES VENTES DE VÉHICULES NEUFS',
  'TOTAL CERTIFIED USED CAR SALES':
    'TOTAL DES VENTES DE VÉHICULES D’OCCASION CERTIFIÉS',
  'TOTAL CAR SALES': 'TOTAL DES VENTES DE VÉHICULES',
  'TOTAL CAR SALES CRITERIA': 'TOTAL DES VENTES DE VÉHICULES – CRITÈRES',
  'SALES LEADER': 'MEILLEUR VENDEUR ',
  'CERTIFIED USED SALES': 'VENTES DE VÉHICULES D’OCCASION CERTIFIÉS',
  'TOTAL USED SALES': 'TOTAL DES VENTES DE VÉHICULES D’OCCASION',
  '% CERTIFIED AND NON CERTIFIED RDR VS FINANCIAL STATEMENTS':
    '% DE RDR CERTIFIÉS ET NON CERTIFIÉS PAR RAPPORT AUX ÉTATS FINANCIERS',
  '% CERTIFIED AND NON CERTIFIED RDR VS FINANCIAL STATEMENTS ATTAINED':
    '% DE RDR CERTIFIÉS ET NON CERTIFIÉS PAR RAPPORT AUX ÉTATS FINANCIERS ATTEINT',
  'TOTAL PRE-OWNED VEHICLE SALES': 'VENTES TOTALES DE VÉHICULES D’OCCASION',
  QUALIFYING: 'ADMISSIBLE',
  'TOTAL COURSES OUTSTANDING': 'TOTAL DES COURS NON SUIVIS',
  'ACURA CONCIERGE 82% OBTAINED (YES/NO)': '82 % ACURA CONCIERGE (OUI/NON)',
  'ACURA CONCIERGE REPORT SCORE': 'RÉSULTAT DU RAPPORT ACURA CONCIERGE',
  'TEMP STATUS QUICK SUMMARY':
    'RÉSUMÉ  DE VOTRE STATUT TEMPORAIRE AU MOMENT DU RAPPORT',
  '* Financial data is up to one months behind':
    "* Les données financières peuvent remonter jusqu'à une mois précédants",

  // kpi
  '{{target}} of Sales People must have completed their online training.':
    '{{target}} de leur personnel des ventes (conseiller des ventes) aient terminé leur formation en ligne.',
  '100% of RSO or Above Zone Group Average':
    '100 % du RSO ou supérieur à la moyenne de groupe de zone',
  'CXI Score': 'Pointage CXI',
  'All online courses must be completed within 90 days and must achieve Certified Professional level.':
    'Tous les cours en ligne doivent être terminés dans les 90 jours et l’accréditation Professionnel certifié doit être obtenue.',
  'E-Mail Accuracy': 'Exactitude de courriel ',
  '% Certified vs. Eligible Certified units':
    'Pourcentage d’unités certifiées vs certifiées et admissibles',
  '% Certified and Non-Certified vs. Financial Statements':
    'Pourcentage de certifiées et non certifiées vs les états financiers',
  'All online courses must be completed within 90 days and must achieve Certified Professional level':
    'Tous les cours en ligne doivent être terminés dans les 90 jours et l’accréditation Professionnel certifié doit être obtenue.',
  '% of attainment of Retail Sales Objective (RSO)':
    "(%) d'atteinte de l'objectif de ventes au detail (RSO)",
  'Must attain a minimum of 80% on the Acura Concierge Report':
    'Doil atteindre un minimum de 80% sur le rapport Acura Concierge',
  'Must attain a minimum of 82% on the Acura Concierge Report':
    'Doil atteindre un minimum de 82% sur le rapport Acura Concierge',
  'Dealership must achieve a minimum of 95% for Sales CXI':
    'La Consession doit atteindre un minimum de 95% de son pointage CXI',
  'Must have 100% of Sales Personnel with more than 1 year of experience trained as Certified Professionals':
    "Doil avair 100 % du personnel des ventes ayant plus d'un 11) an d'experience, farme en tant que professionnel certifie",
  'Minimum ACPOV %vs. total eligible Acura used':
    "%MINMAL TOTAL VEHICULES D'CXCASION ACURA ADMISSIBLES",
  'Minimum RDR vs. FS%': 'MINIMUM RDR VS. % FINANCIERS',
  Qualified: 'qualifié',
  'Recall Escapee % below Zone Average':
    '% de rappels en suspens sous la moyenne de la zone',
  'Recall Escapee % below Zone Average.':
    '% de rappels en suspens sous la moyenne de la zone.',
  'Recalls: Recall Escapee % below Zone Average.':
    'Campagnes de rappels: % de rappels en suspens sous la moyenne de la zone.',
  'Staff: 100% of Parts Counter People must achieve Honda Product Knowledge Certified Level':
    'Personnel: 100 % des préposés au comptoir des pièces doivent atteindre le niveau Connaissance certifiée des produits Honda.',
  'Staff: Minimum of 50% of Parts Counter People must achieve Customer Engagement Leader Level ':
    'Personnel: Au moins 50 % des préposés au comptoir des pièces doivent atteindre le niveau Leader en engagement client.',
  'VOC: Dealership’s overall VOC Service CXI (OSAT, Loyalty, Recommend) must be in the top 15%*':
    'Voix du client: Le résultat actuel du concessionnaire pour le sondage Voix du client en lien avec l’indice de l’expérience client (CXI; satisfaction globale, fidélisation, recommandation) doit se situer parmi le 15 %* supérieur',
  'Staff: 100% of Service Advisors must achieve Honda Product Knowledge Certified Level':
    'Personnel: 100 % des préposés au comptoir des pièces doivent atteindre le niveau Connaissance certifiée des produits Honda.',
  'Staff: 100% of Technicians at Maintenance Level':
    'Personnel: 100 % des techniciens doivent détenir le niveau Entretien.',
  'Staff: 50% of Service Advisors at  Customer Engagement Leader Level':
    'Personnel: Au moins 50 % des conseillers au service doivent atteindre le niveau Leader en engagement client.',
  'VOC: Dealership’s overall VOC Service CXI at or above combined zone group benchmark':
    'Voix du client: Le résultat actuel du concessionnaire pour le sondage Voix du client doit être égal ou supérieur à la moyenne combinée des résultats des groupes de la zone pour les critères de référence',
  'Staff: Minimum of 50% of Parts Counter People must achieve Gold Level':
    "Personnel: Au moins 50 % des préposés au comptoir des pièces doivent atteindre le niveau d'or",
  'Staff: 100% of Service Advisors must achieve Guild Member Certified Level':
    'Personnel: 100 % des CS au niveau Membre de la Guilde',
  'Staff: 50% of Service Advisors at Gold Level':
    'Personnel: Au moins 50 % des CS au niveau Or ',
  // training course
  Core: 'Connaissances Principales',
  'Product Knowledge': 'Connaissance Des Produits',
  'Professional Development': 'Développement Professionnel',
  'Recertification Required': 'Recertification requise',
  'GENERATIONS- Multi-Generational Communication Skills': 'GENERATIONS – Aptitudes à la communication multigénérationnelle',
  'HCW - Honda Culture and History': 'HCW – Histoire et culture de Honda',
  "BLUESKIES2 - Honda's Approach to Greenhouse Gas Reduction": 'BLUESKIES2 – L’approche de Honda à la réduction des GES',
  'POWERTECH - Powertrain and Chassis Technologies': 'POWERTECH – Technologies des groupes propulseurs et des châssis',
  'VLH17TYPER - 2017 Honda Civic Type R Virtual Learning': 'VLH17TYPER – Honda Civic Type R 2017 – Formation virtuelle',
  'VLH19INSIGHT - 2019 Honda Insight Virtual Learning': 'VLH19INSIGHT – Formation virtuelle sur la Honda Insight 2019',
  'ACCORDDELIVERY - Delivering the 10th Generation Accord': 'ACCORDDELIVERY - Livraison de la 10eme generation Accord',
  'CLARITYINTRO - Honda Clarity Plug-In Hybrid': 'CLARITYINTRO – Honda Clarity hybride rechargeable',
  'CRVDELIVERY- Delivering the 5th Generation CR-V': 'CRVDELIVERY- Livraison de la 5eme generation CR-V',
  'HRVINTRO - Product Walkaround: HR-V': 'HRVINTRO - Présentation de produit: HR-V',
  'ODYDELIVERY - Delivering the 5th Generation Odyssey': 'ODYDELIVERY - Livraison de la 5eme generation Odyssey',
  'CO2101 - Understanding CO2 Emissions': 'CO2101 – Comprendre les émissions de CO2',
  'CONNECTEDCAR - Technologies HondaLink': 'CONNECTEDCAR – Technologies HondaLink',
  'ACURALINK2 - AcuraLink Technologies': 'ACURALINK2 - Technologies AcuraLink',
  'ACPOV2 - Acura Certified Pre-Owned Vehicles': 'ACPOV2 – Véhicules d’occasion certifiés Acura',
  'SAFETECHA - Acura Safety Technologies': 'SAFETECHA – Technologies de sécurité Acura',
  'VLAQDE - Quality Delivery Experience (QDE) - Virtual Learning for Sales Consultants': 'VLAQDE - Livraison de qualité - Formation virtuelle pour conseiller aux ventes',
  'ACURABRAND -An Introduction to the Acura Brand': 'ACURABRAND – Introduction à la marque Acura',
  'WALKAROUND- The Product Presentation': 'WALKAROUND – Présentation de produit',
  'RDXDELIVERY- Delivering the RDX': 'RDXDELIVERY– Livraison du RDX',
  'MDXDELIVERY - Delivering the Acura MDX': 'MDXDELIVERY– Livraison de l’Acura MDX',
  'TLXDELIVERY - Delivering the Acura 2nd Generation TLX': 'TLXDELIVERY -Livraison de l’Acura TLX 2eme generation',
  'INTROLMS - Introduction to the Honda Institute LMS': 'INTROLMS - Introduction au système LMS de l’Institut Honda',
  'VLATLXTYPES - 2021 Acura TLX TYPE S Virtual Learning': 'VLATLXTYPES – Apprentissage virtuel de l’Acura TLX TYPE S 2021',
  'VLAMDXTYPES - Acura MDX TYPE S Virtual Learning': 'VLAMDXTYPES – Apprentissage virtuel de l’Acura MDX TYPE S',
  'ACURATECH - Acura Advanced Technologies': 'ACURATECH – Technologies avancées Acura',
  'VLA21SELL - Strategies to Overcoming Objections': 'VLA21SELL – Stratégies pour surmonter les objections',
  '22RDXWALK - 2022 Acura RDX Walkaround': '22RDXWALK - Présentation de produit Acura RDX 2022',
  'CCS1 - Customer Centred Selling Process': 'CCS1 - Processus de vente axée sur le client',
  'AUTOFIN - Introduction to Automotive Finance': 'AUTOFIN - Introduction à Financement de véhicules',
  'DEMODRIVE - Demonstration Drive': 'DEMODRIVE - Essai de démonstration',
  'WALKAROUND - The Product Presentation': 'WALKAROUND - Effectuer une présentation du produit',
  'INTROHONDA - Honda Products and Technology': 'INTROHONDA - Produits et technologies Honda',
  'QDP- Product Delivery': 'QDP- Processus de livraison de qualité',
  'VLHDESALES- Honda Driving Excellence Virtual Learning  Sales': 'VLHDESALES- Formation virtuelle « Sur la route de l’excellence »  Ventes',
  'INFOCONF- Confidentiality & Sharing of Corporate Communication': 'INFOCONF- Confidentialité et partage des communications d’entreprise',
  'LEADMGMT - Lead Management': 'LEADMGMT - Gestion des contacts',
  'VOC101 - Voice of the Customer': 'VOC101 - Voix du client',
  '11GENCIVIC - 11th Generation Civic Product Walkaround and Delivery': '11GENCIVIC - Présentation et livraison de la Civic de 11e génération',
  '22CIVICWALK - 11th Generation Honda Civic Model Launch Video': '22CIVICWALK - Vidéo de lancement de la Honda Civic de 11e génération',
  'ACCORDINTRO - Product Walkaround  Accord & Accord Hybrid': 'ACCORDINTRO - Présentation du produit - Accord et Accord hybride',
  'VLH22HRV - 2nd Generation Honda HR-V Virtual Learning': 'VLH22HRV - Formation virtuelle sur le Honda HR-V de 2e génération',
  '2NDGENHRV - 2nd Generation HR-V Product Walkaround and Delivery': '2NDGENHRV - Présentation et livraison du HR-V de 2e génération',
  '6GENCRVINTRO - 6th Generation Honda CR-V Model Launch Video': '6GENCRVINTRO - Vidéo de lancement du Honda CR-V de 6e génération',
  '6GENCRV - 6th Generation CR-V Product Walkaround and Delivery': '6GENCRV - Présentation et livraison du CR-V de 6e génération',
  'VLH20CRV - 2020 Honda CR-V Virtual Learning': 'VLH20CRV - Formation virtuelle du Honda CR-V 2020',
  '2020CRVH2H - 2020 CR-V vs. RAV4 Head-to-Head Comparison Course': '2020CRVH2H - Comparaison directe du CR-V 2020 au RAV4 2020',
  'VLH19PASSPORT- The Honda Passport Virtual Learning': 'VLH19PASSPORT- Formation virtuelle sur le Honda Passport',
  'VLH19HONDA - What is new for 2019 Pilot': "VLH19HONDA - Nouveautés pour l'année-modèle 2019",
  'VLH18ODYSSEY - 2018 Honda Odyssey Virtual Learning': 'VLH18ODYSSEY - Formation virtuelle du Honda Odyssey 2018',
  'VLH18ODYSSEY - Honda Odyssey Virtual Learning': 'VLH18ODYSSEY - Formation virtuelle du Honda Odyssey',
  'RIDGELINEINTRO- Product Walkaround: 2nd Generation Honda Ridgeline': 'RIDGELINEINTRO- Démonstration du Ridegeline 2ème Génération',
  'POWERTECH - Pow ertrain and Chassis Technologies': 'POWERTECH - Technologies du groupe propulseur',
  'SAFETECHH - Honda Safety Technologies': 'SAFETECHH - Technologies de sécurité Honda',
  'HONDACUV2 - Honda Certified Used Vehicles': 'HONDACUV2 - Véhicules d’occasion certifiés Honda',
  'HONDATECH - Honda In-Vehicle Technology': 'HONDATECH - Technologies de bord Honda',
  'VLHONDASELL - Strategies to Overcoming Objections': 'VLHONDASELL - Stratégies pour surmonter les objections',
  'DRIVE - Introduction to Dealer Request Interactive Vehicle Exchange (DRiVE)': 'DRIVE - Introduction au système d’échange interactif de véhicules à la dema',
  'RER01 - Effective Recruiting Strategies': 'RER01 - Stratégies efficaces de recrutemen',
  'RER02 - Associate Engagement Excellence': 'RER02 - Excellence en engagement des associés',
  'RER03 - Maximizing Associate Retention': 'RER03 - Maximiser la rétention des associés',
  'USEDMGMT01 - Introduction to Used Vehicles Management': 'USEDMGMT01 - Introduction à la gestion de véhicules d’occasion',
  'USEDMGMT02 - Effective Inventory Management': 'USEDMGMT02 - Gestion efficace du stock',
  'USEDMGMT03 - Effective Used Vehicles Marketing & Merchandising': 'USEDMGMT03 - Marketing et commercialisation efficaces des véhicules d’occasion',
  'VLH11GENCIVIC - 11th Generation Honda Civic Virtual Learning': 'VLH11GENCIVIC - Présentation et livraison de la Civic de 11e génération ',
  '22CIVICWALK - 11th Generation Honda Civic Live Interactive Broadcast': '22CIVICWALK - Vidéo de lancement de la Honda Civic de 11e génération ',
  'CONNECTEDCAR - HondaLink Technologies': 'CONNECTEDCAR - Technologies HondaLink',
  'VLH23HRV 2nd Generation Honda HR-V Virtual Learning': 'VLH23HRV Formation virtuelle du Honda HR-V de 2e génération',
  '2NDGENHRV 2nd Generation HR-V Product Walkaround and Delivery': '2NDGENHRV Présentation et livraison du HR-V de 2e génération',
  '6GENCRVINTRO 6th Generation Honda CR-V Model Launch Video': '6GENCRVINTRO Vidéo de lancement du CR-V de 6e génération',
  '6GENCRV 6th Generation CR-V Product Walkaround': '6GENCRV Présentation et livraison du CR-V de 6e génération',
  'ACURABRAND - An Introduction to the Acura Brand': 'ACURABRAND - Introduction à la marque Acura',
  'AUTOFIN - Introduction to Vehicle Financing': 'AUTOFIN - Introduction u financement automobile',
  'FIACRAPLUS - Acura Plus': 'FIACRAPLUS - Acura Plus',
  'FIHOW2EBIZ - How to Biz for F&l Managers': 'FIHOW2EBIZ - Utilisation du système Biz pour les directeurs commerciaux',
  'GENERATIONS - Multi-Generational Communication Skills': 'GENERATIONS - Aptitudes à la communication multigénérationnelle',
  'FIACRALSGD - Acura Lease-Guard': 'FIACRALSGD - Protection pour location-bail Acura',
  'HFS4010 - Acura Precision 2.0 - Enhanced Product Offering': 'HF$4010 - Acura Précision 2.0',
  'CO2101 - Understanding CO2 Emissions WBT': 'CO2101 - Comprendre les émissions de CO2 (formation en ligne)',
  'VOCI01 - Voice of the Customer': 'VOC101 - Voix du client',
  'FIIECERTCI - A Customer-Focused F&I Presentation': 'FIIECERTCI - Présentation axée sur le client au bureau commercial',
  'FIIECERTC2 - Making the IntangibleTangible': 'FIIECERTC2 - Concrétiser le besoin',
  'FIIECERTC3 - Benefits of a Vehicle Service Agreement - Part 1': "FIIECERTC3 - Avantages d'un contrat de garantie prolongée de véhicule - Partie l",
  'FIIECERTC4 - Vehicle Service Agreement Objections': 'FIIECERTC4 - Obiections de contrat de service de véhicule',
  'FIIECERTC5 - Making the Factory Warranty Tangible': 'FIIECERTC5 - Rendre concrète la garantie du fabricant',
  'FIIECERTC6 - Vehicle Service Agreement Sales & Closing Techniques': 'FIIECERTC6 - Contrats de service de véhicules et comment conclure la vente',
  'FIIECERTC7 - HCFI Financing Versus Paying Cash': 'FIIECERTC7 - Financement de HCFI versus payer comptant',
  'FIIECERTC8 - Psychology of Top Performers': 'FIIECERTC8 - Psychologie des meilleurs performants',
  'FIHNDAPLUS - Honda Plus': 'FIHNDAPLUS - Honda Plus',
  'FIHOW2EBIZ - How to eBiz for F&I Managers': 'FIHOW2EBIZ - Utilisation du système Biz pour les directeurs commerciaux',
  'GENERATIONS – Multi-Generational Communication Skills': 'GENERATIONS - Aptitudes à la communication multigénérationnelle',
  'FIHNDALSGD- Honda Lease-Guard': 'FIHNDALSGD - Protection pour location-bail Honda',
  'HFS3510 - Introduction to Honda Advantage': 'HFS3510 - Présentation de Honda Avantage',
  'FIIECERTC1 - A Customer Focused F&I Presentation': 'FIIECERTC1 - Présentation axée sur le client au bureau commercial',
  'INTEGRAINTRO - Acura Integra Product Walkaround': 'INTEGRAINTRO - Ronde de présentation de l’Acura Integra',
  'VLH23HRV - 2nd Generation Honda HR-V Virtual Learning': 'VLH23HRV - Formation virtuelle sur le Honda HR-V de 2e génération',
  'VLHQDE - Quality Delivery Experience (QDE) Virtual Learning': 'VLHQDE - Expérience de livraison de qualité (ELQ) - Formation virtuelle',
  'Training: Maintenance ': 'Formation: Entretien',
  'Training: General Repair ': 'Formation: Réparations générales',
  'Training: Advance Repair ': 'Formation: Réparations avancées',
  'Training: Diagnostic ': 'Formation: Diagnostic',
  'ARANK - Identifying / Reporting Product Safety Issues': 'ARANK - Détecter et signaler les préoccupations sur la sécurité des produits',
  'PARTSMATH - Performance Monitoring and Parts Business Math': 'PARTSMATH - Suivi de la performance et calculs mathématiques...',
  'SDM1 - Time Control & Work Distribution': 'SDM1 - Gestion du temps et répartition du travail',
  'SDM2 - Appointments': 'SDM2 - Rendez–vous',
  'SDM3 - Reception & Consultation': 'SDM3 - Réception et consultation',
  'SDM4 - Quality Assurance & Active Delivery': 'SDM4 - Assurance de la qualité et communication dynamique',
  'SHOPLOAD - Shop Loading and Dispatching': 'SHOPLOAD - Chargement et répartition en atelier',
  'PARTSINV - Inventory Management and Security': 'PARTSINV - Gestion des stocks et de la sécurité',
  'SA-BATT - Honda Battery Testing': 'SA-BATT - Test de batteries',
  'SL1903 - Precautions with Updating ECUs': 'SL1903 - Précautions relatives à la mise à jour des ECU',
  'SDM5 - Customer Experience and Retention': 'SDM5 - xpérience client et rétention',
  'SL2002 - Maintenance Mode': "SL2002 - Mode d'entretien",
  'SL2102 - Passenger Airbag Inflator Recall: SRS Parts Scan app': 'SL2102 - Dispositif de gonflage de coussin gonflable du passager: Application Scan Pièces SRS',
  'NMH1600 - 2016 Honda Pilot Technical Information Guide': 'NMH1600 - Guide d’information technique du MDX 2022',
  'PARTSCTLG - Electronic Parts Catalogue Training': 'PARTSCTLG - Formation sur la nouvelle génération du catalogue des pièces',
  'PARTSINTRO - Introductory Parts Counterperson Training': 'PARTSINTRO - Cours de formation de base du préposé au comptoir des pièces',
  'PARTSOPS  Understanding Parts Operations': 'PARTSOPS - Bien comprendre les opérations du service des pièces',
  'PARTSSELL - Relationship Selling Skills': 'PARTSSELL - Compétences en techniques de vente des relations d’affaires',
  'PARTSVEH  Introduction to Vehicle Systems': 'PARTSVEH - Introduction aux systèmes de véhicule',
  'CSE-FUND - Customer Service Excellence-Fundamentals': 'CSE-FUND - Excellence du service à la clientèle – Principes fondamentaux',
  'PARTSMNGR - Introduction to Managing a Parts Department.': 'PARTSMNGR - Introduction à la gestion d’un service des pièces',
  'PARTSMRKTG - Selling Skills and Marketing': 'PARTSMRKTG - Compétences en techniques de vente et de marketing',
  'PARTSORDER - Parts Order Processing and Facility Efficiency': 'PARTSORDER - Traitement des commandes de pièces et efficacité de l’installation',
  'GI0600 - Maintenance Minder': "GI0600 - Rappel d'Entretien",
  "SA-INTRO - Introduction to the Service Advisor's Role": 'SA-INTRO - Introduction au rôle du conseiller du service',
  'SA-WRO - Writing the Repair Order': 'SA-WRO - Rédaction du bon de réparation',
  'VLS2022EDGE - Introducing the New Service Edge App': 'VLS2022EDGE - Introduction à la nouvelle application L’expertise Service',
  'VLSELLSERV - Selling Automotive Services': 'VLSELLSERV - Vente de services automobiles',
  'PCPENVIRO - Precision Crafted Performance and Environmental Responsibility': 'PCPENVIRO - Performance conçue avec précision et responsabilité environnementale',
  'VLA19RDX - 2019 RDX Virtual Learning': 'VLA19RDX - Formation virtuelle sur le RDX 2019',
  'VLA21TLX - 2021 Acura TLX Virtual Learning': 'VLA21TLX - Formation virtuelle sur la TLX 2021',
  'VLA22MDX - 2022 Acura MDX Virtual Learning': 'VLA22MDX - Formation virtuelle sur le MDX 2022',
  'VLAMDXTYPES - Acura MDX  TYPE S Virtual Learning': 'VLAMDXTYPES - Formation virtuelle sur l’Acura MDX TYPE S',
  'NMA1400 - 2019 Acura RDX Technical Information Guide': 'NMA1400 - Guide d’information technique du RDX 2019',
  'NMA1500 - 2021 Acura TLX Technical Information Guide': 'NMA1500 - Guide d’information technique de la TLX 2021',
  'NMA1600 - 2022 Acura MDX Technical Information Guide': 'NMA1600 - Guide d’information technique du MDX 2022',
  'NMA1700 - 2022 Acura MDX Type S Technical Information Guide': 'NMA1700 - Guide d’information technique pour l’Acura MDX 2022 Type S',
  'NMA1800 - 2023 Integra Technical Information Guide': 'NMA1800 - Guide d’information technique pour l‘Acura Integra 2023',
  'BK0850 - Brake Inspection': 'BK0850 - Inspection des Freins',
  'EL0120 - Battery Capacity and Charging': 'EL0120 - Capacité et Charge de la batterie',
  'EL0275 - Charging Systems': 'EL0275c - Systèmes de charge',
  'EL6650 - ECONomy (ECON) Mode': 'EL6650 - ÉCONomique (ECON)',
  'GI0050 - MOST network and OTA updates': 'GI0050 - MOST et mise à  jour par liaison radio',
  "GI0220- ESIS - Using Honda/Acura's Electronic Service Information System (ESIS)": "GI0220- ESIS - Utilisation du Système électronique d'information pour le service (ESIS) de Honda/Acura",
  'GI1210 - Five Step Diagnostic Process': 'GI1210 - Procédure de Diagnostic en Cinq Étapes',
  'EL7370 - HMI Display Audio with Touchpad Interface': 'EL7370 - Système audio sur affichage avec interface de pavé tactile',
  'GI0110 - ETecH, Connected Dealer and the Honda Diagnostic Systems': 'GI0110 - ETecH, Concessionnaire Connecté et Systèmes de diagnostic Honda',
  'GI0250 - 4th Generation Service Manual': 'GI0250 -  Manuel d’entretien de 4e génération',
  'PD0600 - Pre-Delivery Inspection': 'PD0600 - L’inspection prélivraison',
  'SL1801 -  Passenger Airbag Inflator Type PSPI-6, Installation Precautions': 'SL1801 -  Précautions d’installation du dispositif de gonflage de coussin gonflable du passager de type PSPI-6.',
  'SS0100 -  Initiator-less and Initiator Type TPMS': 'SS0100 -  TPMS avec et sans amorceur',
  'AT0100 - Installing Remanufactured AT': 'AT0100 - Installation des Boîtes de Transmission Automatiques',
  'AT0300 - Diagnosing AT Leaks': 'AT0300 - Diagnostic des fuites de la boîte de vitesses automatique',
  'AT0800 - A/T Mechanical Diagnosis': 'AT0800c - Diagnostic mécanique de la boîte automatique',
  'AT0900 - A/T Diagnosis: Electrical': 'AT0900 - Diagnostic de transmission automatique: Électricité',
  'AT1220 - Second generation CVT operation': 'AT1220 – Fonctionnement de la CVT deuxième génération',
  'AT1600- AWD Torque Vectoring System': 'AT1600- Système de distribution de couple de traction intégrale (AWD)',
  'AT1900 - Real Time AWD(TM) system': 'AT1900 - Système de transmission intégrale en temps réel - Real Time AWD ™',
  'AT2300- Electronic Gear Selection System': 'AT2300- Système de sélection de rapports électronique',
  'AT2400 - 10 Speed Automatic Transmission': 'AT2400 - Fonctionnement de la boîte de vitesses automatique à 10 rapports',
  'BK2600 - VSA Components & Function': 'BK2600 - Stabilité Assistée du Véhicule (VSA) C&F',
  'BK2710 - Hill Start Assist System': 'BK2710 - Système d’assistance au départ en pente.',
  'BK2720 - Brake Override System': 'BK2720 - Système de priorité aux freins',
  'BK2730 - Electric Parking Brake': 'BK2730 - Frein de stationnement électrique',
  'BK2740 - Automatic Brake Hold': 'BK2740 - Maintien automatique des freins',
  'BK2750 - Electric Servo Brake System': 'BK2750 - Système de servofrein électrique',
  'EL0410S - Testing & Maintaining the Battery Skills Module': 'EL0410s - Test et Entretien de la Batterie',
  'EL0500 - Basic Electrical Tools': 'EL0500 - Outils Électriques de Base',
  'EL0600 - Control Unit Initiation and Key off Activity': "EL0600 - Initiation de l'Unité de Contrôle et Activité Principale",
  'EL0700 - Using a Digital Multi-Meter': "EL0700 - Utilisation d'un Multimètre Numérique",
  'EL1950 - Using the New Electrical Wiring Diagram (EWD)': 'EL1950 - Utilisation des schémas de câblage électrique nouvelle (EWD)',
  'EL2050 - Troubleshooting using the new Electrical Wiring Diagrams': "EL2050 - Dépannage à l'aide des nouvelles schémas de câblage électrique",
  'EL8900 - Non-Hybrid Idle Stop': 'EL8900 - Arrêt au ralenti sur un véhicule non hybride',
  'EN0150 - Pumping Theory and Cylinder Performance Testing': 'EN0150 - Théorie concernant le pompage et test de performance des cylindres',
  'EN0250 - Engine Symptoms and Troubleshooting': 'EN0250 - Symptômes de moteur et dépannage',
  'EN0350 - Lubrication System Construction & Function': 'EN0350 - Construction et fonctionnement du système de lubrification',
  'EN0450 - Cooling System Construction & Function': 'EN0450 - Système de refroidissement construction et fonction',
  'EN0550 - Mechanical seals': 'EN0550 - Joints mécaniques',
  'EN0750 - Cam Chain Service': 'EN0750 - Entretien de la chaîne pour cames',
  'EN0850 - Valve train inspection and adjustment': 'EN0850 - Inspection et ajustement du dispositif de commande des soupapes',
  'EN0950 - Internal Engine Inspection': 'EN0950 - Inspection internes du moteur',
  'EN2250 - VTEC Design and Troubleshooting': 'EN2250c -  Conception et dépannage système VTEC',
  'EN2500 - i-VTEC Variable Cylinder Management': 'EN2500 - Gestion Variable des Cylindres i-VTEC',
  'EN2900 - V6 VCM II': 'EN2900c - V6 VCM II',
  'EN3000- Turbocharger Construction and Function': 'EN3000- Systèmes turbocompresseurs',
  'GI0055 - MOST Bus Network Overview, Troubleshooting, and Repairs': 'GI0055 - Aperçu, diagnostic de pannes et réparation du réseau en bus MOST',
  'GI0650 - Threaded Fasteners and Torque': 'GI0650 - Fixations filetées et couple',
  'GI3000V - ADAS Aiming Procedures': 'GI3000V - Procédures d’orientation des ADAS',
  'GI3100 - ADAS Sensing': 'GI3100 - Systèmes de détection ADAS',
  'GI5000 - Troubleshooting Interior Squeaks and Rattles': "GI5000 - Dépannage de les bruits d'habitacle",
  'GI5100 - Troubleshooting Wind Noise': 'GI5100 - Diagnostic de problème de bruit de vent',
  'GI5200 - Troubleshooting Water Leaks': "GI5200 - Résolution des problème de fuites d'eau",
  'MT1300 - M/T Troubleshooting and Diagnosis': 'MT1300c - Diagnostic et dépannage de boîte de vitesses manuelle',
  'SS0050 - Introduction to Suspension Systems': 'SS0050 - Introduction aux systèmes de suspension',
  'SS0130 - Troubleshooting Suspension Noise': 'SS0130 - Diagnostiquer les bruits de suspension',
  'SS0140 - Hydraulic Power Steering': 'SS0140 - Direction assistée hydraulique',
  'SS0150 - Steering, Suspension and Driveline Inspection': 'SS0150 - Inspection de la Direction, Suspension et la Chaîne de Distribution',
  'SS0210 -Troubleshooting Tire Vibration': 'SS0210 - Diagnostic de vibration des pneus',
  'SS0550 - Electric Power Steering': 'SS0550c - Direction à assistance électrique',
  'SS1500 - Precision All Wheel Steering': 'SS1500 - Système de précision toutes roues directionnelles',
  'AC0310 - A/C refrigeration Principles': 'AC0310c - Principes de climatisation',
  'AC0510 - Component Operation and Service': 'AC0510c - Fonctionnement et entretien des composants',
  'AC0610S - A/C Operation and Inspection Skills Module': 'AC0610S - Fonctionnement et inspection du système de climatisation',
  'AC0710 - A/C System Performance Testing': 'AC0710c - Test de performance système de climatisation',
  'AC0720S - A/C System Performance Testing Skills Module': 'AC0720s - Test de performance du système de climatisation – Module de compétence',
  'AC0950 - Introduction to R-1234yf Refrigerant': 'AC0950 - Introduction au fluide frigorigène R-1234yf',
  'AC2650 - Hybrid HVAC Systems': 'AC2650 - Systèmes HVAC de véhicules hybrides',
  'EL0210 - Energy Management System (EMS)': 'EL0210 - Sstème de gestion de la batterie (EMS)',
  'EL0250 - Battery Management System (BMS)': 'EL0250 - Système de gestion de la batterie (BMS)',
  'EL1300 - Understanding Electricity': "EL1300 - Principes de l'Électricité",
  'EL1400 - Electromagnetic Fields': 'EL1400 - Champs Électromagnétiques',
  'EL1500 - Basic Circuit Components': 'EL1500 - Composants de Base des Circuits',
  'EL1600 - Basic Circuit Types': 'EL1600 - Types de Circuits de Base',
  'EL1700 - Basic Electrical Faults': 'EL1700 - Défauts Électriques de Base',
  'EL1800S - Building a Basic Circuit Skills Module': "EL1800S - Montage d'un Circuit de Base",
  'EL2200 - Power Distribution': 'EL2200 - Distribution Électrique',
  'EL2300 - Ground Distribution': 'EL2300 - Distribution des Mises à la Masse',
  'EL4250 - ECU Key Writing': 'EL4250 - Écriture de clé ECU',
  'EL7240 - High Voltage Battery Charging and Maintenance': 'EL7240 - Charge et entretien de batteries haute tension',
  'FE0400 - Introduction to PGM-FI': 'FE0400 - Introduction au Système PGM-FI',
  'FE0550 - Fundamentals of fuel injection': 'FE0550 - Principes fondamentaux de l’injection de carburant',
  'FE0900 - Introduction to Fuel System Hydraulics': 'FE0900 - Introduction au circuit d’alimentation',
  'FE1000 - Fundamentals of Ignition Systems': 'FE1000 - Principes fondamentaux des systèmes d’allumage',
  'FE1200 - Introduction to Electronic Throttle Control Systems': 'FE1200 - Introduction aux Commandes Électroniques des Papillons à Gaz',
  'FE1301S - ECM PCM Inputs Calculations and Outputs Skills Module': 'FE1301S - Entrées, calculs et sorties de l’ECM PCM',
  'FE1610S - PGM-FI Sensor Range Performance Skills Module': 'FE1610S - Portée/rendement du capteur PGM-FI',
  'FE1700 - Fuel System Electrical': 'FE1700 - Système électrique du circuit d’alimentation',
  'FE1910S -  Electronic Throttle Control Systems': 'FE1910S -  Système de commande électronique  du papillon',
  'FE2305S - Fuel Outputs Skills Module': 'FE2305 - Débit de Carburant',
  'FE2310S - Fuel Pump Circuit Analysis Skills Module': 'FE2310 - Analyse du circuit de pompe à carburant',
  'FE2320S - Fuel Pump Circuit Troubleshooting Skills Module': 'FE2320 - Dépistage du circuit de pompe à carburant',
  'FE2600 - Critical Current Air/Fuel Ratio Sensor': 'FE2600 - Capteur du Rapport Air/Carburant du Courant Critique',
  'FE2700S - Valve Control Systems Skills Module': 'FE2700 - Module de compétences en systèmes de commande de vannes',
  'FE2800 - Oxygen Ion Pumping Air Fuel Sensor': 'FE2800 - Capteur de Pompe Ionique pour Rapport Air-Carburant',
  'FE4310S - Verify Fuel and Emission Repairs Skill Module': 'FE4310 - Vérifier les réparations liées au carburant et aux émissions',
  'FE7000 - Direct Injection Engine': 'FE7000c - Moteur à injection directe',
  'RS0500 - Restraint System Components and Operation I': 'RS0500 - Composants du dispositif de retenue et fonctionnement 1',
  'RS0600 - Restraint System Components and Operation II': 'RS0600 - Composants et fonctionnement des systèmes de retenue II',
  'RS0700 - Restraints Troubleshooting Procedures': 'RS0700 - Procédures de dépanage des systèmes de retenue',
  'RS0800 - Restraints System Repair Procedures': 'RS0800 - Procédures de réparation des sytèmes de retenue',
  'EL3900 - Transistors used in Honda Circuits': 'EL3900 - Les transistors utilisés dans les circuits Honda',
  'EL4000 - Electrical Motor Types': 'EL4000 - Types de Moteurs Électriques',
  'EL4100S - Testing Electrical Motors Skills Module': 'EL4100 - Test des Moteurs Électriques',
  'EL4150 - On Vehicle Electrical testing': 'EL4150c - Test électrique sur véhicule',
  'EL4300 - Identifying ECU terminal': 'EL4300 - Identifier les bornes dapos ECU',
  'EL4400 - Multiplex Systems': 'EL4400 - Systèmes Multiplex',
  'EL4550 - Control Unit Input tests': "EL4550c - Tests d'entrée d’unité de contrôle",
  'EL4600 - Communication Networks': 'EL4600 - Réseaux de Communication',
  'EL4700 - Switch and Analog Inputs': 'EL4700 - Interrupteurs et Entrées de Courant Analogique',
  'EL4800 - ECU Control Module Outputs': 'EL4800 - Sorties du Module de Contrôle ECU',
  'EL4900S - Switch & Analog Inputs Skills Module': 'EL4900 - Interrupteurs et entrées de courant analogique',
  'EL5000S - Test Control Module Outputs Skills Module': 'EL5000 - Test de Sorties du Module de Contrôle',
  'EL5050 - Testing and Troubleshooting Key Off Draw': 'EL5050c - Test et dépannage fuite de tension clé-off',
  'EL5100 - Serial Network Failures': 'EL5100 - Défaillances du Réseau en Série',
  'EL5300 - ECU Self Diagnostics': 'EL5300 - Diagnostics Propres du ECU',
  'EL5400 - Introduction to Electrical Troubleshooting': 'EL5400 - Introduction au Dépannage Électrique',
  'EL5600 - Troubleshooting by Dividing a Circuit': 'EL5600 - Dépannage en Divisant un Circuit',
  'EL5700 - Troubleshooting Relay Circuits': 'EL5700 - Diagnostic des circuits de relais',
  'EL5800 - Troubleshooting Short Circuits': 'EL5800 - Dépannage des Courts Circuits',
  'EL5900S - Troubleshooting by Dividing a Circuit Skills Module': 'EL5900 - Dépannage en Divisant un Circuit',
  'EL6000S - Troubleshooting Relays Skills Module': 'EL6000 - Dépannage des Relais',
  'EL6100S - Troubleshooting Short Circuits Skills Module': 'EL6100 - Dépannage des Courts Circuits',
  'EL6200 - Body Electrical Scan Tool Troubleshooting': 'EL6200 - Dépannage avec Outil Électrique de Balayage de la Carroserie',
  'EL6400 - Immobilizer System': 'EL6400 -Système d’immobilisation',
  'EL6500 - B-CAN Construction and Function': 'EL6500 - Construction et Fonctionnement de B-CAN',
  'EL6750 - Second Generation Keyless Access Systems': "EL6750 - Système d'accès sans clé de deuxième génération",
  'EL7250 - Hybrid Technology': 'EL7250 - Technologie hybride',
  'EL7265- Three Motor Hybrid Systems': 'EL7265 - Système hybride à 3 moteurs électriques',
  'EL7285 - Handling High Voltage Components': 'EL7285 - Manipulation de composants à haute tension',
  'EL8410 - Second Generation Power Tailgate Design and Service': 'EL8410 - Conception et entretien du hayon électrique de 2e génération',
  'EL8720 - Remote Engine Start System Operation': 'EL8720 - Fonctionnement du système de démarrage à distance',
  'EL8800 - Driving Position Memory System': 'EL8800 - Systéme de mémorisation des positions du siége conducteur',
  'EL9300 - Keyless Access System': 'EL9300c - Système d’accès sans clé',
  'EL9310S - Immobilizer Type 7 (Keyless Access/One Push Start) System Skill Module': 'EL9310 - Système d’antidémarrage Type 7 (Accès sans clé et démarrage par bouton poussoir)',
  'FE2510S - VTEC System Operation and Testing Skills Module': 'FE2510S - Fonctionnement et test du système VTEC',
  'FE2810S - Air/Fuel (AF) Ratio Sensors Skills Module': 'FE2810 - Capteurs de Rapport Air/Essence (AF)',
  'FE3300 - Catalytic Converter Operation': 'FE3300 - Opération du Convertisseur Catalytique',
  'FE5000 - EGR Systems': 'FE5000 -  Systèmes EGR',
  'FE5105S - EGR Operations and Testing Skills Module': 'FE5105S - Fonctionnement et essai du système EGR',
  'FE5110S - EGR Troubleshooting Skills Module': 'FE5110 - Dépannage du Système EGR',
  'FE5300 - Oxygen Sensor Fuel Trim Operation': "FE5300 - Opération de la Correction de l'Alimentation par le Capteur d'Oxygène",
  'FE5400 - Oxygen Sensor Fuel Trim Diagnosis': "FE5400 - Diagnostic de la Correction de l'Alimentation par le Capteur d'Oxygène",
  'FE5410 - Air Fuel Feedback': 'FE5410 - Rétroaction Air/Essence',
  'FE5440S - Identifying fuel trim failures (MAF) Skills Module': "FE5440 - Défaillances de Correction d'Essence (MAF)",
  'FE5450S - Troubleshooting Fuel Trim Failure - MAF Control Skills Module': 'FE5450 -  Diagnostic d’une panne reliée à la correction de l’alimentation en carburant - contrôle du MAF',
  'FE5800 - ORVR Operation & Diagnostics': 'FE5800 - Opération et Diagnostic du ORVR',
  'FE6100 - LEV II EVAP System with EONV': 'FE6100 - Système EVAP LEV II avec EONV',
  'FE6310S - Test & Inspect LEVII EVAP Systems Skills Module': "FE6310 - Test et Inspection des Systèmes d'ÉVAP LEVII",
  'FE6400 - Jet Purge System': 'FE6400 - Système EVAPà purge par gicleur',
  'FE6600S - HDS Data List Troubleshooting Skills Module': 'FE6600 - Diagnostic à l’aide de la liste de données du HDS',
  'FE6700 - Troubleshooting Output Component High and Low Voltage Dtcs': 'FE6700 -Dépannage de Dtc de Tension Elevee ou Faible des Composants de Sortie',
  'FE6710 - Troubleshooting Three Wire Sensor High And Low Voltage Dtcs': 'FE6710 - Dépannage de Dtc de Tension Faible ou Elevee de Capteur a Trois Cables',
  'FE6720 - Troubleshooting Two Wire Sensor High And Low Voltage Dtcs': 'FE6720 - Dépannage de Dtc de Tension Faible ou Elevee de Capteur a Deux Cables',
  'FE6730S - Diagnosing Comprehensive Component DTCs Skills Module': 'FE6730 - Diagnostic des DTC Complets de Composants',
  'FE6800 - Troubleshooting Engine Misfires': 'FE6800 - Dépannage De Ratés De Moteur.',
  'FE6810S - Misfire Analysis Skills Module': 'FE6810 - Analyse des Ratés',
  'FE6820S - Misfire Troubleshooting Skills Module': 'FE6820 - Dépannage des Ratés',
  'FE7010S - Direct Injection Skills Module': 'FE7010S – Injection directe',
  'AT2000 - 9-Speed Automatic Transmission Construction & Function': 'AT2000 – Conception et fonctionnement de la transmission automatique à 9 rapports',
  'EL7265- 3 Motor Hybrid System': 'EL7265 - Système hybride à 3 moteurs électriques',
  'NMH1800- 2017 Honda Ridgeline Technical Information Guide': 'NMH1800 - Guide d’information technique pour le Honda Ridgeline 2017',
  'NMH2000- 2018 Honda Odyssey Technical Information Guide': 'NMH2000 - Guide d’information technique pour l’Odyssey 2018',
  'NMH2200 - 2018 Honda Accord Technical Information Guide': 'NMH2200 - Guide d’information technique pour la Honda Accord 2018',
  'NMH2600 - 2022 Civic Technical Information Guide': 'NMH2600 - Guide d’information technique pour le Honda Civic 2022',
  'VLHDESERVICE - Honda Driving Excellence Virtual Learning  Service': 'VLHDESERVICE - Formation virtuelle : Sur la route de l’excellence Honda – Service',
  'SL1902 - CR-V Rear Frame Stiffener Corrosion Inspection Precautions': 'SL1902 - Précautions d’inspection de la corrosion des raidisseurs de cadre arrière du CR-V',
  'SL2001- Gauge Control Module Reflash Tool Overview': 'SL2001 - Aperçu de l’outil de reprogrammation du module de commande des instruments',
  'SL2101 - Element Rear Frame Stiffener Corrosion Inspection and Repair Procedure': 'SL2101 - Procédures d’inspection et de réparation des renforts de cadre arrière de l’Element',
  'VLH18ACCORD - 2018 Honda Accord Virtual Learning': 'VLH18ACCORD - Formation virtuelle sur la Honda Accord 2018',
  'VLHSACOM1 - Managing Customer Expectations -Honda': 'VLHSACOM1 -Gestion des attentes des clients',
  'NMH2100 - 2017 Honda Civic Type R Technical Information Guide': 'NMH2100 - Guide d’information technique pour le modèle Honda Civic Type R 2017',
  'NMH2700 - 2023 CR-V & CR-V HYBRID TECHNICAL INFORMATION GUIDE': 'NMH2700 - Guide d’information technique pour les CR-V et CR-V Hybrid 2023',
  'SL2201 - 2006-14 Ridgeline Rear Frame C-Crossmember Corrosion Inspection and Repair Procedure': 'SL2201 -',
  'AT2500 - Hybrid E-Drive System Operation': 'AT2500 - Fonctionnement du système E-Drive hybride',
  'FE4310S - Readiness Monitors Skills Modules': 'FE4310S - Vérifier les réparations liées au carburant et aux émissions',
  'EL8720  Remote Engine Start System Operation': 'EL8720 -',
  'EL6800- Second Generation Display Audio/Display Audio Plus': 'EL6800 -',
  'EL9110- Plug-In Vehicle Telematics': 'EL9110 -',

  // excel
  LABEL: 'ÉTIQUETER',

  // months
  Jan: 'janv',
  Feb: 'fév',
  Mar: 'mars',
  Apr: 'avril',
  May: 'mai',
  Jun: 'juin',
  Jul: 'juil',
  Aug: 'août',
  Sept: 'sept',
  Sep: 'sept',
  Oct: 'oct',
  Nov: 'nov',
  Dec: 'déc',
  'janv YTD': 'CA de janv',
  'fév YTD': 'CA de fév',
  'mars YTD': 'CA de mars',
  'avril YTD': 'CA de avril',
  'mai YTD': 'CA de mai',
  'juin YTD': 'CA de juin',
  'juil YTD': 'CA de juil',
  'août YTD': 'CA de août',
  'sept YTD': 'CA de sept',
  'sep YTD': 'CA de sept',
  'oct YTD': 'CA de oct',
  'nov YTD': 'CA de nov',
  'déc YTD': 'CA de déc',

  // tokyo version
  Blueprints: 'Plans',
  'Extended Sidebar': 'Barre latérale étendue',
  'Accent Sidebar': "Barre latérale d'accentuation",
  'Accent Header': 'En-tête accentué',
  'Boxed Sidebar': 'Barre latérale en boîte',
  'Collapsed Sidebar': 'Barre latérale réduite',
  'Bottom Navigation': 'Navigation du bas',
  'Top Navigation': 'Navigation supérieure',
  'Lead Developer': 'Développeur principal',
  'Mega menu': 'Méga menu',
  Doctors: 'Médecins',
  Reports: 'Rapports',
  'Custom dashboard built using the included components':
    "Tableau de bord personnalisé construit à l'aide des composants inclus",
  Hospital: 'Hôpital',
  Export: 'Exportation',
  'Last year': "L'année dernière",
  Expenses: 'Dépenses',
  Products: 'Des produits',
  Statistics: 'Statistiques',
  General: 'Général',
  Automation: 'Automatisation',
  'Data Display': 'Affichage des données',
  Calendar: 'Calendrier',
  Mailbox: 'Boites aux lettres',
  Analytics: 'Analytique',
  Banking: 'Bancaire',
  Commerce: 'Commerce',
  Crypto: 'Crypto',
  Finance: 'La finance',
  Fitness: 'Aptitude',
  Healthcare: 'Soins de santé',
  'Doctors Page': 'Page des médecins',
  'Hospital Overview': "Aperçu de l'hôpital",
  Helpdesk: "Bureau d'aide",
  Learning: 'Apprentissage',
  Monitoring: 'Surveillance',
  Tasks: 'Tâches',
  Applications: 'Applications',
  'File Manager': 'Gestionnaire de fichiers',
  'Jobs Platform': "Plateforme d'emploi",
  Messenger: 'Messager',
  'Projects Board': 'Conseil des projets',
  Management: 'La gestion',
  Users: 'Utilisateurs',
  'List All': 'Tout lister',
  'User Profile': "Profil de l'utilisateur",
  Projects: 'Projets',
  Shop: 'Magasin',
  'Products List': 'Liste de produits',
  'View Product': 'Voir le produit',
  'Create Product': 'Créer un produit',
  Invoices: 'Les factures',
  'View Single': 'Voir Single',
  'Auth Pages': "Pages d'authentification",
  Login: 'Connexion',
  Basic: 'De base',
  Cover: 'Couvrir',
  Register: "S'inscrire",
  Wizard: 'Magicien',
  'Recover Password': 'Récupérer mot de passe',
  Status: 'Statut',
  STATUS: 'STATUT',
  Foundation: 'Fondation',
  'Extra Pages': 'Pages supplémentaires',
  'Error 404': 'Erreur 404',
  'Error 500': 'Erreur 500',
  'Coming Soon': 'À venir',
  Maintenance: 'Entretien',
  Overview: 'Aperçu',
  'Layout Starters': 'Démarreurs de mise en page',
  'Starter Kit 1': 'Kit de démarrage 1',
  'Starter Kit 2': 'Kit de démarrage 2',
  Documentation: 'Documentation',
  Welcome: 'Bienvenue',
  Help: 'Aider',
  'Features tour': 'Visite des fonctionnalités',
  'Getting started guide': 'Guide de Démarrage',
  'Contact support': 'Contactez le support',
  Version: 'Version',
  Search: 'Chercher',
  Notifications: 'Notifications',
  Settings: 'Paramètres',
  'Language Switcher': 'Sélecteur de langue',
  'Sign out': 'Déconnexion',
  'Change Theme': 'Change le thème',
  'View all notifications': 'Afficher toutes les notifications',
  'Tokyo NextJS Typescript Admin Dashboard':
    "Tableau de bord d'administration de Tokyo React",
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
    "Modèle React haute performance construit avec de nombreux composants Material-UI puissants dans plusieurs niches de produits pour des processus de développement d'applications rapides et parfaits",
  'Browse Live Preview': "Parcourir l'aperçu en direct",
  'Live Preview': 'Aperçu en direct',
  'Key Features': 'Principales caractéristiques',
  dashboards: 'tableaux de bord',
  applications: 'applications',
  'management sections': 'sections de gestion',
  'dark/light themes': 'thèmes sombres / clairs',
  components: 'composants',
  'Some of the features that make Tokyo one of the best admin templates available today':
    "Certaines des fonctionnalités qui font de Tokyo l'un des meilleurs modèles d'administration disponibles aujourd'hui",
  'Design Source Files': 'Fichiers source de conception',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
    "Commencez à travailler sur votre projet directement à partir de l'un des kits de démarrage inclus ou utilisez les fichiers Figma / Sketch pour créer d'abord un prototype",
  'We only translated a small part of the template, for demonstration purposes':
    "Nous n'avons traduit qu'une petite partie du modèle, à des fins de démonstration",
  'CXI: The Overall Satisfaction score for the Business Office':
    'Indice de l’expérience client (CXI) : Le résultat global de satisfaction du bureau commercial',
  'Minimum units sold (New & CUV)': 'Minimum d’unités vendues (neuves et VOC)',
  'VOC Q2.01 - Number of Surveys': 'Voix du client Q2.01 - Nombre de sondages',
  'VOC Q2.01 - NUMBER OF SURVEYS': 'Voix du client Q2.01 - Nombre de sondages',
  'VOC: Dealership’s overall VOC Service CXI in the top 15%': 'Voix du client : Indice global de l’expérience client (CXI) au Service de la concession parmi le 15 % supérieur',
  'VOC: DEALERSHIP’S OVERALL VOC SERVICE CXI IN THE TOP 15%': 'Voix du client : Indice global de l’expérience client (CXI) au Service de la concession parmi le 15 % supérieur',
  'VOC Service CXI Dealer': 'Voix du client – Indice de l’expérience client (CXI) au Service, Concession',
  'VOC SERVICE CXI DEALER': 'Voix du client – Indice de l’expérience client (CXI) au Service, Concession',
  'VOC Service CXI Combined Group Benchmark': 'Voix du client – Indice de l’expérience client (CXI) au Service, Moyenne combinée des résultats du groupe',
  'VOC SERVICE CXI COMBINED GROUP BENCHMARK': 'Voix du client – Indice de l’expérience client (CXI) au Service, Moyenne combinée des résultats du groupe',
  'is top 15%': 'parmi le 15 % supérieur',
  'IS TOP 15%': 'parmi le 15 % supérieur',
  '% Technicians at Maintenance': '% de techniciens ayant atteint le niveau « Entretien »',
  '% TECHNICIANS AT MAINTENANCE': '% de techniciens ayant atteint le niveau « Entretien »',
  'VOC Q2.01 Student Score': 'Voix du client Q2.01 Résultat de l’étudiant',
  'VOC Q2.01 STUDENT SCORE': 'Voix du client Q2.01 Résultat de l’étudiant',
  'VOC Q2.01 Number of Surveys': 'Voix du client Q2.01 Nombre de sondages',
  'VOC Q2.01 NUMBER OF SURVEYS': 'Voix du client Q2.01 Nombre de sondages',
  'VOC Q2.01 Benchmark': 'Voix du client Q2.01 Critère de référence',
  'VOC Q2.01 BENCHMARK': 'Voix du client Q2.01 Critère de référence',
  'VOC Q2.01 Adjusted Group Rank': 'Voix du client Q2.01 Rang ajusté dans le groupe',
  'VOC Q2.01 ADJUSTED GROUP RANK': 'Voix du client Q2.01 Rang ajusté dans le groupe',
  'VOC Q2.01 Rank Top 10%': 'Voix du client Q2.01 Rang dans le 10 % supérieur',
  'VOC Q2.01 RANK TOP 10%': 'Voix du client Q2.01 Rang dans le 10 % supérieur',
  'Recall Escapee': 'Campagnes De Rappels',
  'RECALL ESCAPEE': 'CAMPAGNES DE RAPPELS',
  'Recall Escapee Zone Average': '% de rappels moyenne de la zone.',
  'RECALL ESCAPEE ZONE AVERAGE': '% de rappels moyenne de la zone.',
  '% SA Member': '% des CS au niveau Membre de la Guilde',
  '% SA MEMBER': '% des CS au niveau Membre de la Guilde',
  '% SA Gold': '% des CS au niveau Or.',
  '% SA GOLD': '% des CS au niveau Or.',
  '% PCP Member': '% des PCP au niveau Membre de la Guilde',
  '% PCP MEMBER': '% des PCP au niveau Membre de la Guilde',
  '% PCP Gold': '% des PCP au niveau Or.',
  '% PCP GOLD': '% des PCP au niveau Or.',
  'Core Training': 'Connaissances principales',
  'CORE TRAINING': 'Connaissances principales',
  'PRODUCT KNOWLEDGE': 'Connaissance des produits',
  'Professional development': 'Développement Professionnel',
  'PROFESSIONAL DEVELOPMENT': 'Développement Professionnel',
  'MAINTENANCE': 'Entretien',
  'GENERAL REPAIR': 'Réparations générales',
  'ADVANCED REPAIR': 'Réparations avancées',
  'Diagnostic': 'Diagnostic',
  'DIAGNOSTIC': 'Diagnostic',
  'Dealer Escapee': 'Campagnes De Rappels',
  'DEALER ESCAPEE': 'CAMPAGNES DE RAPPELS',
  'Zone average escapee': '% de rappels moyenne de la zone.',
  'ZONE AVERAGE ESCAPEE': '% de rappels moyenne de la zone.',
  '% PCP Certified': '% PCP doivent atteindre le niveau Connaissance certifiée des produits Honda',
  '% PCP CERTIFIED': '% PCP doivent atteindre le niveau Connaissance certifiée des produits Honda',
  '% SA Certified': '% CS doivent atteindre le niveau Connaissance certifiée des produits Honda',
  '% SA CERTIFIED': '% CS doivent atteindre le niveau Connaissance certifiée des produits Honda',
  '% SA Leader': '% CS doivent atteindre le niveau Leader en engagement client',
  '% SA LEADER': '% CS doivent atteindre le niveau Leader en engagement client',
  '% PCP Leader': '% PCP doivent atteindre le niveau Leader en engagement client',
  '% PCP LEADER': '% PCP doivent atteindre le niveau Leader en engagement client',
  'Staff: 50% of Service Advisors must achieve Customer Engagement Leader Level': 'Personnel: Au moins 50 % des conseillers au service doivent atteindre le niveau Leader en engagement client.',
  'Staff: 50% of Parts Counter People must achieve Customer Engagement Leader Level': 'Personnel: Au moins 50 % des préposés au comptoir des pièces doivent atteindre le niveau Leader en engagement client.',
  'Staff: 50% of Service Advisors at Customer Engagement Leader Level': 'Personnel: Au moins 50 % des conseillers au service doivent atteindre le niveau Leader en engagement client.',
  'Staff': 'Personnel',
  'VOC Q2.01 - OSAT': 'Q2.01 du sondage Voix du client - Satisfaction globale au Service',
  'Training: Maintenance Completed': 'Formation: Entretien',
  'Training: General Repair Completed': 'Formation: Réparations générales',
  'Training: Advance Repair Completed': 'Formation: Réparations avancées',
  'Training: Diagnostic Completed': 'Formation: Diagnostic',
  'Staff: 100% of Parts Counter People must achieve Guild Member Certified Level': 'Personel: 100 % des PCP au niveau Membre de la Guilde',
  'Staff: 50% of Service Advisors People must achieve Gold Level': 'Personel: Au moins 50 % des CS au niveau Or.',
  'Staff: 50% of Parts Counter People must achieve Gold Level': 'Personel: Au moins 50 % des PCP au niveau Or.',
  'Training: Product Knowlegde': 'Formation: Connaissance des produits',
  'Top 15%': 'le 15 % supérieur',
  'VOC: Service Advisor’s combined VOC Q2.01 - OSAT Service must be at or above combined zone group benchmark for the period of June 1, 2022, to December 31, 2022': 'VDC: Le résultat combiné pour le conseiller de service à la Q2.01 du sondage Voix du client - Satisfaction globale au Service, doit être égal ou supérieur à la moyenne combinée des résultats des groupes de la zone(1à3et4à5)pourlapériodedu 1er juin 2022 au 31 décembre 2022.',
  'VOC: Q2.01 - OSAT Service must be in the top 10% of their respective combined zone group for the period of June 1, 2022, to December 31, 2022': 'VDC: Le résultat à la Q2.01 du sondage Voix du client - Satisfaction globale au Service doit se situer parmi le 10 % supérieur de la moyenne combinée des résultats des groupes de lazone pour la période du 1er juin 2022 au 31 décembre 2022.',
  'VOC: Q2.01 - OSAT Service must be in the top 10% of their respective combined zone group (1-3 & 4-7) for the period of June 1, 2022, to December 31, 2022': 'VDC: Le résultat à la Q2.01 du sondage Voix du client - Satisfaction globale au Service doit se situer parmi le 10 % supérieur de la moyenne combinée des résultats des groupes de lazone pour la période du 1er juin 2022 au 31 décembre 2022.',
  'VOC: Service Advisor’s combined VOC Q2.01 - OSAT Service must be at or above combined zone group benchmark (1-3 & 4-7) for the period of June 1, 2022, to December 31, 2022': 'VDC: Le résultat combiné pour le conseiller de service à la Q2.01 du sondage Voix du client - Satisfaction globale au Service, doit être égal ou supérieur à la moyenne combinée des résultats des groupes de la zone(1à3et4à7)pourlapériodedu 1er juin 2022 au 31 décembre 2022.',

  // training report dashboard titles ....
  'Total Report': 'Rapport total',
  'Total Reports': 'Rapports total',
  'search': 'recherche',
  'Historical Reports': 'Rapport historique',
  'Training Report Centre': 'Centre de rapport de formation',
  'Admin': 'Administratrice',
  'report': 'Rapport',
  'Total': 'Total',
  '2023 Dealership Training Report': 'Rapport de formation des concessionnaires 2023',
  'No record found': 'Aucun Enregistrement Trouvé',
  'The Training Report All Positions shows Training completion by category and role.': "Le rapport de formation pour tous les postes indique l'achèvement de la formation par catégorie et par rôle.",

  // Dealership page ...
  'How this report is calculated': 'Comment ce rapport est calculé',
  'Method': 'Méthode',
  'Academic': 'Académique',
  'Download': 'Télécharger',
  'Raw': 'Brute',
  'Progressive': 'Progressive',
  'Dealership Summary by Curriculum': "Résumé du concessionnaire par programme d'études",
  'How it works': 'Comment ça fonctionne',
  'Layer 1: Dealership Summary by Curriculum': 'Couche 1 : Résumé du concessionnaire par cursus',
  'Core, Product Knowledge, Professional Development, Maintenance': 'Base, Connaissance des produits, Développement professionnel, Maintenance',
  'Layer 2: Drill down to the Certification Level within a Dealership': "Couche 2 : Exploration jusqu'au niveau de certification au sein d'une concession",
  'See summary of all Parts Managers and their 2023 Honda Parts Manager Core Completion': "Voir le résumé de tous les gestionnaires de pièces et leur achèvement de base du gestionnaire de pièces Honda 2023",
  'Layer 3: Drill down to the Student within Certification Level': "Couche 3 : accédez à l'étudiant dans le niveau de certification",
  'Drill down to a single Parts Manager within the 2023 Honda Parts Manager Core certification to see completion dates or incomplete courses': "Accédez à un seul gestionnaire de pièces dans le cadre de la certification Honda Parts Manager Core 2023 pour voir les dates d'achèvement ou les cours incomplets",
  'Report Rules & Dealership Summary by Curriculum Calculation': 'Règles du rapport et résumé du concessionnaire par calcul du programme',
  'Report Rules': 'Règles de rapport',
  'Calculation is based on the Dealer as a whole': 'Le calcul est basé sur le concessionnaire dans son ensemble',
  'All positions within the Dealer must achieve 100% completion with no missed deadlines': 'Tous les postes au sein du concessionnaire doivent être complétés à 100 % sans délais manqués',
  'If a dealer misses a deadline throughout the year, they are “flagged” and disqualified from the program. This is denoted by the text “disqualified”': "Si un concessionnaire manque une échéance tout au long de l'année, il est « signalé » et disqualifié du programme. Ceci est indiqué par le texte 'disqualifié'",
  'If the student starts within 90 days of the course launch or prior to the deadline, they are not included in the calculation': "Si l'étudiant commence dans les 90 jours suivant le lancement du cours ou avant la date limite, il n'est pas inclus dans le calcul",
  'Not included means that they are not included in the calculation of the overall score': "Non inclus signifie qu'ils ne sont pas inclus dans le calcul de la note globale",
  'This is denoted by the text “Excluded”': 'Ceci est indiqué par le texte "exclu"',
  'If a Dealer is disqualified due to training completion, the Dealer will be disqualified for program year': "Si un concessionnaire est disqualifié en raison de l'achèvement de la formation, le concessionnaire sera disqualifié pour l'année du programme",
  'NOTE: Approved flag removals will occur at the student level, not dealer level': "REMARQUE : les suppressions de drapeaux approuvées se produiront au niveau de l'étudiant, et non au niveau du revendeur",
  'Breakdown by Dealership Calculation': "Répartition par concessionnaire Calcul",
  'Curriculum score is based on the average results for participating positions': 'Le score du programme est basé sur les résultats moyens des postes participants',
  'To refer to position calculation, click on one of the bold values (scores) below to dig into Layer 2 of the report, called the Certification within the Dealership report': "Pour vous référer au calcul de la position, cliquez sur l'une des valeurs en gras (scores) ci-dessous pour accéder à la couche 2 du rapport, appelée Certification dans le rapport du concessionnaire",
  'For Example: the Core Sales Score for a Dealership is the average of Core completion scores for all included Sales positions in the Certification within the Dealership Report (Layer 2)': "Par exemple : le score de vente de base pour un concessionnaire est la moyenne des scores d'achèvement de base pour tous les postes de vente inclus dans la certification dans le rapport de concession (couche 2)",
  'Calculation Method': 'Méthode de calcul',
  'When “Academic” Calculation is selected, this report features the following Academic Calculations': "Lorsque le calcul « Académique » est sélectionné, ce rapport présente les calculs académiques suivants",
  '“All or nothing” approach': "Approche « tout ou rien »",
  'No completions = 0': 'Aucune réalisation = 0',
  'No users = NA': 'Aucun utilisateur = NA',
  'For example: when 2/2 students are complete within a Certification = 100%, when 1/2 students are complete within a Certification = 0%': 'Par exemple : lorsque 2/2 étudiants ont terminé une certification = 100 %, lorsque 1/2 étudiants ont terminé une certification = 0 %',
  'Calculation Breakdown by Position': 'Répartition du calcul par poste',
  'POSITIONS THAT PARTICIPATE': 'POSTES QUI PARTICIPENT',
  'CURRICULUMS INCLUDED': 'PROGRAMMES INCLUS',
  'Effective Date': 'Date effective',
  'Target: one technician per dealership.': 'Objectif : un technicien par concession',
  'Note: Zone and Dealer overall calculations include EV certifications which are applicable only for EV dealerships.': 'Remarque : Les calculs globaux pour la zone et le concessionnaire comprennent les certifications pour VE, lesquelles ne s’appliquent qu’aux concessions de VE.',
  '* Excluded from recognition programs, and not included in overall calculation': '* Exclus des programmes de reconnaissance, et non inclus dans le calcul général.',
  'EV' : 'VÉ',
};

export default frJSON;
