import { createContext, useEffect, useReducer } from 'react';
import axios from 'src/utils/axios';
import Axios from 'axios';
import { api } from 'src/config';
import jwt from 'jsonwebtoken';
import { JWT_SECRET } from 'src/utils/jwt';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setDefaultApi = () => {
  Axios.defaults.baseURL = api.url;
};

const setSession = (accessToken) => {
  setDefaultApi();

  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    let selected_language = localStorage.getItem('lng');
    if (selected_language) {
      localStorage.setItem('lng', selected_language);
    } else {
      localStorage.setItem('lng', 'en');
    }

    Axios.defaults.headers.common['x-access-token'] = `${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user_name');
    localStorage.removeItem('report_name');
    delete Axios.defaults.headers.common['x-access-token'];
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginToken: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  let navigate = useNavigate();
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        // console.log('🚀 ~ accessToken', accessToken);
        if (accessToken && jwt.verify(accessToken, JWT_SECRET)) {
          setSession(accessToken);
          const response = await Axios.get('/api/user/me');
          const { user } = response.data;

          localStorage.setItem('accessRights', user?.permissions?.items);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (username, password) => {
    setDefaultApi();
    const response = await Axios.post('/api/auth/signin', {
      username,
      password
    });
    // console.log('loginToken=>', response);
    const { accessToken, user } = response.data;
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    localStorage.removeItem('accessRights');
    localStorage.removeItem('user_name');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('redirectURL');
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const loginToken = async (accessToken, redirectURL) => {
    try {
      logout();
      if (redirectURL) {
        localStorage.setItem('redirectURL', redirectURL);
      }
      if (accessToken && jwt.verify(accessToken, JWT_SECRET)) {
        setSession(accessToken);
        const response = await Axios.get('/api/user/me');
        const { user } = response.data;

        dispatch({
          type: 'LOGIN',
          payload: {
            user
          }
        });
      } else {
        setSession(null);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      setSession(null);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };


  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        loginToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
