import { useRef, useState, useEffect, useContext } from 'react';
import axios from 'axios';

import {
  // IconButton,
  Box,
  List,
  ListItem,
  Divider,
  Typography,
  ListItemText,
  // alpha,
  Popover,
  // Tooltip,
  styled
  // useTheme
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import useAuth from 'src/hooks/useAuth';
// import Text from 'src/components/Text';

// import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import internationalization from 'src/i18n/i18n';
import { useTranslation } from 'react-i18next';

// import deFlag from 'country-flag-icons/3x2/DE.svg';
// import usFlag from 'country-flag-icons/3x2/US.svg';
// import caFlag from 'country-flag-icons/3x2/CA.svg';
// import esFlag from 'country-flag-icons/3x2/ES.svg';
// import frFlag from 'country-flag-icons/3x2/FR.svg';
// import cnFlag from 'country-flag-icons/3x2/CN.svg';
// import aeFlag from 'country-flag-icons/3x2/AE.svg';
import './index.css';
import { TranslationContext } from 'src/contexts/TranslationContext';

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);

// const ImageWrapper = styled('img')(
//   () => `
//         width: 30px;
// `
// );

// const IconButtonWrapper = styled(IconButton)(
//   ({ theme }) => `
//   width: ${theme.spacing(4)};
//   height: ${theme.spacing(4)};
//   border-radius: ${theme.general.borderRadiusLg};
// `
// );

function LanguageSwitcher() {
  // const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { user } = useAuth();

  let { textTranslation } = useContext(TranslationContext);
  const id = user.id;
  // console.log('🚀 ~ id', id);
  // const getLanguage = i18n.language;
  // const theme = useTheme();
  const switchLanguage = async ({ lng }) => {
    internationalization.changeLanguage(lng);
    await axios.post(`/api/user/me/lang/${lng}`);
    // window.location.reload(true);
  };
  const localStorageLng = localStorage.getItem('lng');
  // console.log('🚀 ~ localStorageLngfirst', localStorageLng);
  useEffect(() => {
    internationalization.changeLanguage(localStorageLng);
  }, [localStorageLng]);

  // console.log('🚀 ~ localStorageLng', localStorageLng);

  // console.log('🚀 ~ user', user);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <>
      {/* <Tooltip arrow title={t('Language Switcher')}> */}
      <button
        type="button"
        className="languageSwitchButton"
        // color="secondary"
        ref={ref}
      // sx={{
      //   mx: 1,
      //   background: alpha(theme.colors.error.main, 0.1),
      //   transition: `${theme.transitions.create(['background'])}`,
      //   color: theme.colors.error.main,

      //   '&:hover': {
      //     background: alpha(theme.colors.error.main, 0.2)
      //   }
      // }}
      >
        <LanguageIcon fontSize="small" />
        <div style={{ fontSize: '15px', paddingLeft: '12px' }}>
          {/* {t('Language')} */}
          {/* <span className={localStorageLng === 'en' ? 'active' : ''} onClick={() => {
            switchLanguage({ lng: 'en' });
            localStorage.setItem('lng', 'en');

          }}>  */}
          <span className={localStorageLng === 'en' ? 'active' : ''} onClick={() => {
            switchLanguage({ lng: 'en' });
            localStorage.setItem('lng', 'en');
            window.location.reload(true);
          }}>{localStorageLng === 'en' ? <span>English</span> : <span>Anglais</span>} | </span>
          <span className={localStorageLng === 'fr' ? 'active' : ''} onClick={() => {
            switchLanguage({ lng: 'fr' });
            localStorage.setItem('lng', 'fr');
            window.location.reload(true);
          }}>{localStorageLng === 'en' ? <span>French</span> : <span>Français</span>}</span>
          {/* </span> */}
        </div>
        {/* {getLanguage === 'de' && <ImageWrapper alt="German" src={deFlag} />} */}
        {/* {getLanguage === 'en' && (
          <div style={{ position: 'relative' }}>
            <ImageWrapper alt="English" src={caFlag} />
            <div
              style={{
                position: 'absolute',
                margin: 'auto',
                top: 0,
                left: 6,
                color: '#263238',
                fontWeight: 'bolder',
                letterSpacing: 2.5
              }}
            >
              EN
            </div>
          </div>
        )} */}
        {/* {getLanguage === 'en-US' && <ImageWrapper alt="English" src={caFlag} />}
        {getLanguage === 'en-GB' && <ImageWrapper alt="English" src={caFlag} />} */}
        {/* {getLanguage === 'es' && <ImageWrapper alt="Spanish" src={esFlag} />} */}
        {/* {getLanguage === 'fr' && (
          <div style={{ position: 'relative' }}>
            <ImageWrapper alt="French" src={caFlag} />

            <div
              style={{
                position: 'absolute',
                margin: 'auto',
                top: 0,
                left: 6,
                color: '#263238',
                fontWeight: 'bolder',
                letterSpacing: 2.5
              }}
            >
              FR
            </div>
          </div>
        )} */}
        {/* {getLanguage === 'cn' && <ImageWrapper alt="Chinese" src={cnFlag} />} */}
        {/* {getLanguage === 'ae' && <ImageWrapper alt="Arabic" src={aeFlag} />} */}
      </button>
      {/* </Tooltip> */}
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        // onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{
            maxWidth: 240
          }}
        >
          <SectionHeading variant="body2" color="text.primary">
            {textTranslation('Language Switcher')}
          </SectionHeading>
          <List
            sx={{
              p: 2
            }}
            component="nav"
          >
            <ListItem
              className={
                localStorageLng === 'en' || localStorageLng === 'en-US'
                  ? 'active'
                  : ''
              }
              button
              onClick={() => {
                switchLanguage({ lng: 'en' });
                localStorage.setItem('lng', 'en');
                // handleClose();
              }}
            >
              {/* <ImageWrapper alt="English" src={caFlag} /> */}
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary={textTranslation('English')}
              />
            </ListItem>
            {/* <ListItem
              className={getLanguage === 'de' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'de' });
                handleClose();
              }}
            >
              <ImageWrapper alt="German" src={deFlag} />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="German"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'es' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'es' });
                handleClose();
              }}
            >
              <ImageWrapper alt="Spanish" src={esFlag} />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="Spanish"
              />
            </ListItem> */}
            <ListItem
              className={localStorageLng === 'fr' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'fr' });
                localStorage.setItem('lng', 'fr');
                // handleClose();
              }}
            >
              {/* <ImageWrapper alt="French" src={caFlag} /> */}
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary={textTranslation('French')}
              />
            </ListItem>
            {/* <ListItem
              className={getLanguage === 'cn' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'cn' });
                handleClose();
              }}
            >
              <ImageWrapper alt="Chinese" src={cnFlag} />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="Chinese"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'ae' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'ae' });
                handleClose();
              }}
            >
              <ImageWrapper alt="Arabic" src={aeFlag} />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="Arabic"
              />
            </ListItem> */}
          </List>
          <Divider />
          {/* <Text color="warning">
            <Box
              p={1.5}
              display="flex"
              alignItems="flex-start"
              sx={{
                maxWidth: 340
              }}
            >
              <WarningTwoToneIcon fontSize="small" />
              <Typography
                variant="body1"
                sx={{
                  pl: 1,
                  fontSize: theme.typography.pxToRem(12)
                }}
              >
                {t(
                  'We only translated a small part of the template, for demonstration purposes'
                )}
                !
              </Typography>
            </Box>
          </Text> */}
        </Box>
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
