import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
// i18n date-fns
import { format as formatDate, isDate } from 'date-fns';
import { enCA, fr } from 'date-fns/locale';

// import LanguageDetector from 'i18next-browser-languagedetector';
import deJSON from './translations/de';
import enJSON from './translations/en';
import esJSON from './translations/es';
import frJSON from './translations/fr';
import aeJSON from './translations/ae';
import zhJSON from './translations/zh';

const resources = {
  de: { translation: deJSON },
  en: { translation: enJSON },
  es: { translation: esJSON },
  fr: { translation: frJSON },
  ae: { translation: aeJSON },
  cn: { translation: zhJSON }
};

// date formating
const locales = { enCA, fr };

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false,
    lng: 'en',
    fallbackLng: 'en',
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        console.log('value : ', value);
        if (isDate(value)) {
          const locale = locales[lng];
          if (format === 'long') return formatDate(value, 'PP', { locale });
          return formatDate(value, format, { locale });
        }

        return value;
      }
    }
  });

export default i18n;
