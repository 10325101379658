import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);
const DownloadReportContainer = Loader(lazy(() => import('src/content/DownloadReport/DownloadReportContainer')));

const downloadReportRoutes = [
    {
        path: '/:key/name/:filename',
        element: <DownloadReportContainer />
    }
];

export default downloadReportRoutes;
