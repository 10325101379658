import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const About = Loader(lazy(() => import('src/content/About')));
const TermsOfService = Loader(
  lazy(() => import('src/content/About/TermsOfService/TermsOfService'))
);
const Privacy = Loader(lazy(() => import('src/content/About/Privacy/Privacy')));

const aboutRoute = [
  {
    path: '/',
    element: <About />
  },
  {
    path: '/terms',
    element: <TermsOfService />
  },
  {
    path: '/privacy',
    element: <Privacy />
  }
];

export default aboutRoute;
