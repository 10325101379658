import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router';
import 'bootstrap/dist/css/bootstrap.css';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import { backArrow } from './config';

function App() {
  const { user } = useAuth();
  // console.log('🚀 ~ user', user);

  localStorage.setItem('lng', user?.lang || 'en');

  const routerCopy = [...router];
  let navigate = useNavigate();

  if (user) {
    // user.permissions = ['Recognition.Dashboard.Access'];
    let accessCheck = [];
    // console.log('In app: ', user);
    user?.permissions?.items?.map((item) => accessCheck.push(item));
    // let LeaderbordAccessCheck = accessCheck.includes(
    //   'Recognition.Leaderboard.Access'
    // );
    // console.log('🚀 ~ LeaderbordAccessCheck', LeaderbordAccessCheck);

    let DealershipOverview = accessCheck.includes(
      'Dealership.Training.Overview'
    );
    let StudentOverview = accessCheck.includes(
      'Dealership.Training.Student'
    );
    let AdminOverview = accessCheck.includes(
      'Report.Dashboard.Admin'
    );
    // console.log('🚀 ~ DashboardAccessCheck', DashboardAccessCheck);
    //  if (DashboardAccessCheck === true && LeaderbordAccessCheck === false) {
    let filteredRoutes = routerCopy[3].children.filter(
      (item) =>
        item.path !== 'leaderboard' &&
        !(item.path === 'dashboard' && item.children[0].path === '/:id')
    );
    // console.log('🚀 ~ filteredRoutes', filteredRoutes);
    let redirectUrl = localStorage.getItem('redirectURL');
    routerCopy[3].children = filteredRoutes;
    if (localStorage.getItem('redirectURL')) {
      filteredRoutes[0].element = <Navigate to={redirectUrl} replace />;
      routerCopy[3].children = filteredRoutes;
      localStorage.removeItem('redirectURL');
    } else {
      if (StudentOverview) {
        filteredRoutes[0].element = <Navigate to="student" replace />;
        routerCopy[3].children = filteredRoutes;
      }
      if (DealershipOverview && user.have_training) {
        filteredRoutes[0].element = <Navigate to={`student/${user.id}`} replace />;
        routerCopy[3].children = filteredRoutes;
      }
      if (DealershipOverview && !user.have_training) {
        filteredRoutes[0].element = <Navigate to="gm-dashboard" replace />;
        routerCopy[3].children = filteredRoutes;
      }
      if (AdminOverview) {
        filteredRoutes[0].element = <Navigate to="ndashboard" replace />;
        routerCopy[3].children = filteredRoutes;
      }
    }

    // if (DealershipOverview === true) {

    //   let filteredRoutes = routerCopy[3].children.filter(
    //     (item) => !(item.path === 'gm-dashboard' && item.children[0].path === '/')
    //   );
    //   // console.log('🚀 ~ filteredRoutes', filteredRoutes);
    //   filteredRoutes[0].element = <Navigate to="leaderboard" replace />;
    //   routerCopy[3].children = filteredRoutes;
    // }

    /*  if (DashboardAccessCheck === false && LeaderbordAccessCheck === true) {
       let filteredRoutes = routerCopy[3].children.filter(
         (item) => !(item.path === 'dashboard' && item.children[0].path === '/')
       );
       // console.log('🚀 ~ filteredRoutes', filteredRoutes);
       filteredRoutes[0].element = <Navigate to="leaderboard" replace />;
       routerCopy[3].children = filteredRoutes;
     }
     if (DashboardAccessCheck === false && LeaderbordAccessCheck === false) {
       console.log('Both false..', routerCopy[3]);
       let filteredRoutes = routerCopy[3].children.filter(
         (item) =>
           item.path !== 'dashboard' &&
           item.path !== 'leaderboard' &&
           item.path !== 'about'
       );
       // console.log('🚀 ~ filteredRoutes', filteredRoutes);
       filteredRoutes[0].element = <Navigate to="no_data" replace />;
       routerCopy[3].children = filteredRoutes;
     } */
  }
  const content = useRoutes(routerCopy);
  const auth = useAuth();
  // let myToken = localStorage.getItem('accessToken');
  // console.log('🚀 ~ myToken', myToken);
  // if (myToken === null) {
  //   <Navigate to="/account/login" />;
  // }

  const APP_ID = 'fk3j9qi9';
  const userEmail = user?.email;
  const userName = user?.name;
  const userId = user?.id;
  const companyName =
    backArrow.name === 'Honda'
      ? 'Honda TRC'
      : backArrow.name === 'Acura'
        ? 'Acura TRC'
        : 'TRC';

  window.intercomSettings = {
    app_id: APP_ID,
    name: userName, // Full name
    email: userEmail, // Email address
    user_id: userId, // current_user_id
    company: companyName,
    vertical_padding: 27,
    horizontal_padding: 0
  };
  (function () {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      const i = function (args) {
        i.c(...args);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${APP_ID}`;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />

          {auth.isInitialized ? content : <AppInit />}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
