import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);
const GapsReportIndex = Loader(lazy(() => import('src/content/GapsReport/GapIndex')));

const gapsReportRoutes = [
    {
        path: '/',
        element: <GapsReportIndex />
    }
];

export default gapsReportRoutes;
