// import { Navigate, useParams } from 'react-router-dom';
// import useAuth from 'src/hooks/useAuth';

// const GuestSSO = () => {
//   const { token } = useParams();
//   const { loginToken } = useAuth();
//   try {
//     loginToken(token);
//   } catch (err) {
//     return <Navigate to="/account/login" />;
//   }

//   const { isAuthenticated } = useAuth();
//   if (isAuthenticated) {
//     return <Navigate to="/dashboard" />;
//   }

//   return <Navigate to="/account/login" />;
// };

// export default GuestSSO;
import { Navigate, useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

const GuestSSO = () => {
  const { token, redirect } = useParams();
  // console.log('🚀 ~ token', token);
  const { user, isAuthenticated, loginToken } = useAuth();
  localStorage.removeItem('redirectURL');
  console.log('User => : ', user);

  let DashboardAdmin = user?.permissions?.items?.map((item) =>
    item.includes('Report.Dashboard.Admin')
  );
  let DealershipTrainingOverview = user?.permissions?.items?.map((item) =>
    item.includes('Dealership.Training.Overview')
  );

  // console.log('🚀 ~ DashboardAccessCheck', DashboardAccessCheck);

  let LeaderbordAccessCheck = user?.permissions?.items?.map((item) =>
    item.includes('Recognition.Leaderboard.Access')
  );

  console.log('DashboardAdmin => ', DashboardAdmin, '🚀 ~ LeaderbordAccessCheck', LeaderbordAccessCheck);

  try {
    loginToken(token, redirect);
  } catch (err) {
    return <Navigate to="/account/login" />;
  }


  if (isAuthenticated) {
    <Navigate to="/dashboard" />
  }
  return <Navigate to="/" />;
};

export default GuestSSO;
