import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
// import NewDashboard from 'src/content/NewDashboard';

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);
const NewDashboard = Loader(lazy(() => import('src/content/NewDashboard/NewDashboardIndex')));


const newDashRoutes = [
    {
        path: '/',
        element: <NewDashboard />
    },

];

export default newDashRoutes;

