import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);
const StudentDashboard = Loader(lazy(() => import('src/content/Student/Dashboard/Index')));


const StudentRoutes = [
    {
        path: '/:student_id',
        element: <StudentDashboard />
    },
    {
        path: '/',
        element: <StudentDashboard />
    }

];

export default StudentRoutes;
