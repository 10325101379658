import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { backArrow } from '../../../../config';
import './index.css';
import { TranslationContext } from 'src/contexts/TranslationContext';

// console.log('🚀 ~ backArrow', backArrow);
function BackToHondaHub() {
  const { t } = useTranslation();
  let { textTranslation } = useContext(TranslationContext);
  // const navigate = useNavigate();
  const handleClick = () => {
    // navigate('hondaHub');
    window.location.href = backArrow.url;
  };
  return (
    <div>
      <button type="button" className="hondaHubButton" onClick={handleClick}>
        <KeyboardBackspaceIcon />
        <div
          style={{
            fontSize: '17px',
            marginLeft: '10px'
          }}
        >
          {textTranslation(`Back To ${backArrow.name} Hub`)}
        </div>
      </button>
    </div>
  );
}

export default BackToHondaHub;
