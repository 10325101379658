const enJSON = {
  today: 'Today is {{ date, long }}',
  updateDate: 'Data updated on {{effectiveDate,long}}',
  trainingDate: '{{val,long}}',
  asOf: 'As of {{effectiveDateLeaderboard,long}}',
  updateAsOf: '{{updateAsOfDate,long}}',
  updateAsOfDate: '{{updateAsOfDate,long}}'
};

export default enJSON;
