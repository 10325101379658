/* eslint-disable no-bitwise */
// export const JWT_SECRET = '(3K1S9@;d)22*l1_1Z';
export const JWT_SECRET = '(3K1!9@;d)Z1*l1_1Z';
// export const JWT_EXPIRES_IN = 3600 * 24 * 2;

// export const sign = (payload, privateKey, header) => {
//   const now = new Date();
//   header.expiresIn = new Date(now.getTime() + header.expiresIn);
//   const encodedHeader = btoa(JSON.stringify(header));
//   const encodedPayload = btoa(JSON.stringify(payload));
//   const signature = btoa(
//     Array.from(encodedPayload)
//       .map((item, key) =>
//         String.fromCharCode(
//           item.charCodeAt(0) ^ privateKey[key % privateKey.length].charCodeAt(0)
//         )
//       )
//       .join('')
//   );

//   return `${encodedHeader}.${encodedPayload}.${signature}`;
// };

// export const decode = (token) => {
//   const [encodedHeader, encodedPayload, signature] = token.split('.');
//   const header = JSON.parse(atob(encodedHeader));
//   const payload = JSON.parse(atob(encodedPayload));
//   const now = new Date();

//   if (now < header.expiresIn) {
//     throw new Error('Expired token');
//   }

//   const verifiedSignature = btoa(
//     Array.from(encodedPayload)
//       .map((item, key) =>
//         String.fromCharCode(
//           item.charCodeAt(0) ^ JWT_SECRET[key % JWT_SECRET.length].charCodeAt(0)
//         )
//       )
//       .join('')
//   );

//   if (verifiedSignature !== signature) {
//     throw new Error('Invalid signature');
//   }

//   return payload;
// };

// export const verify = (token, privateKey) => {
//   const [encodedHeader, encodedPayload, signature] = token.split('.');
//   console.log(encodedHeader, '/', encodedPayload, '/', signature);
//   const header = JSON.parse(atob(encodedHeader));
//   const payload = JSON.parse(atob(encodedPayload));
//   const now = new Date();

//   if (now < header.expiresIn) {
//     throw new Error('The token is expired!');
//   }

//   const verifiedSignature = btoa(
//     Array.from(encodedPayload)
//       .map((item, key) =>
//         String.fromCharCode(
//           item.charCodeAt(0) ^ privateKey[key % privateKey.length].charCodeAt(0)
//         )
//       )
//       .join('')
//   );
//   console.log('verifiedSignature=>', verifiedSignature);
//   console.log('signature=>', signature);
//   if (verifiedSignature !== signature) {
//     throw new Error('The signature is invalid!');
//   }

//   return payload;
// };
