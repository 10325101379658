import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Allcourses = Loader(lazy(() => import('src/content/Admin/Courses/AllCourses/CourseIndex')));
const CoursesIncluded = Loader(lazy(() => import('src/content/Admin/Courses/CourseIncluded/Index')));
const CorrectionsCoursesIndex = Loader(lazy(() => import('src/content/Admin/Courses/UserCorrectionCourses/CorrectionsCoursesIndex')));
const UserCorrectionCoursesList = Loader(lazy(() => import('src/content/Admin/Courses/UserCoursesList/UserCoursesListIndex')));
const UserAccessManagerIndex = Loader(lazy(() => import('src/content/Admin/Courses/UserAccessManager/UserAccessManagerIndex')));
const TranslationList = Loader(lazy(() => import('src/content/Translation/index')));
const ReportOptions = Loader(lazy(() => import('src/content/Dashboard/ReportOptions/index')));
const ReportOptionsManager = Loader(lazy(() => import('src/content/Dashboard/ReportOptionsManager/ReportOptionsManagerIndex')));
const UserDealershipAccess = Loader(lazy(() => import('src/content/Admin/UserDealershipAccess/UserDealershipAccessIndex')));
const UserManger = Loader(lazy(() => import('src/content/Admin/UserManager/UserManagerIndex')));
const UserActivity = Loader(lazy(() => import('src/content/UserActivity/index')));
const UserActivityView = Loader(lazy(() => import('src/content/UserActivity/user')));


const adminRoutes = [
  {
    path: '/translation/list',
    element: <TranslationList />
  },
  {
    path: '/courses',
    element: <Allcourses />
  },
  {
    path: '/course/report/:id',
    element: <CoursesIncluded />
  },
  {
    path: '/course/report/:id/userException',
    element: <CorrectionsCoursesIndex />
  },
  {
    path: '/course/report/:id/userException/:user_id/userCourses',
    element: <UserCorrectionCoursesList />
  },
  {
    path: '/course/report/:id/accessManager',
    element: <UserAccessManagerIndex />
  },
  {
    path: '/training/options',
    element: <ReportOptions />
  },
  {
    path: '/training/manager',
    element: <ReportOptionsManager />
  },
  {
    path: '/user/dealership-access',
    element: <UserDealershipAccess />
  },
  {
    path: '/user/manager',
    element: <UserManger />
  },
  {
    path: '/user-activity',
    element: <UserActivity />
  },
  {
    path: '/user-activity/:loc_id/:start_date/:end_date',
    element: <UserActivityView />
  },
];

export default adminRoutes;
