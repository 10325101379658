import { Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';

const Guest = ({ children }) => {
  const { isAuthenticated } = useAuth();
  let navigate = useNavigate();
  const { user } = useAuth();
  // console.log('user:', user, 'isAuthenticated: ', isAuthenticated);
  let selected_language = localStorage.getItem('lng');
  if (selected_language) {
    localStorage.setItem('lng', selected_language);
  } else {
    localStorage.setItem('lng', 'en');
  }
  if (isAuthenticated) {
    if (user?.permissions?.items.includes('Report.Dashboard.Admin')) {
      return <Navigate to="/ndashboard" />
    } else if (user?.permissions?.items.includes('Dealership.Training.Overview')) {
      return <Navigate to="/gm-dashboard" />
    } else if (user?.permissions?.items.includes('Dealership.Training.Student')) {
      return <Navigate to="/student" />
    } else {
      return <Navigate to="/ndashboard" />
    }

  }

  return <>{children}</>;
};

Guest.propTypes = {
  children: PropTypes.node
};

export default Guest;
