import useAuth from 'src/hooks/useAuth';
import { ListSubheader, alpha, Box, List, styled } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BarChartIcon from '@mui/icons-material/BarChart';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import AppBlockingOutlinedIcon from '@mui/icons-material/AppBlockingOutlined';
import SidebarMenuItem from './item';
import gapReportIcon from './images/gap-report-icon.svg';
import menuItems from './items';
import LogOut from '../SidebarFooter/index';
import LanguageSwitcher from '../../Header/Buttons/LanguageSwitcher/index';
import BackToHondaHub from './BackToHondaHub';
import { useCallback, useContext, useEffect, useState } from 'react';
import { TranslationContext, TranslationProvider } from 'src/contexts/TranslationContext';
import axios from 'axios';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};
   


    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
      
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      

      .MuiListItem-root {
        padding: 0.1px 0;
         
        
        

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};
          

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color:#767480;
          font-family: Open Sans;
          font-size:16px;
          line-height:23px;
          font-weight:700;
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
              color:#6F6E7A;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.Mui-active{
            color:#44434A;
            background-color:#F3F3F8;
            .MuiSvgIcon-root{
              color:#44434A;
            }
          },
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color:#44434A;

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color:#44434A;
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};

          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
            
          }

          .MuiListItem-root {
            padding: 1px 0;
            
            

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};
              
             

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
    'transform',
    'opacity'
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.Mui-active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({ items, path }) => {
  return (
    <SubMenuWrapper>
      <List component="div">
        {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
      </List>
    </SubMenuWrapper>
  )
};


const reduceChildRoutes = ({ ev, path, item }) => {
  const key = item.name;
  const exactMatch = item.link
    ? !!matchPath(
      {
        path: item.link,
        end: true
      },
      path
    )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
        {
          path: item.link,
          end: false
        },
        path
      )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
        icon={item.icon}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useAuth();
  let { textTranslation, getAllDealershipReport, reportDealershipData } = useContext(TranslationContext);

  useEffect(() => {
    if (user.have_training) {
      getAllDealershipReport();
    }
  }, [getAllDealershipReport, user.have_training]);

  let accessCheck = [];
  user?.permissions?.items?.map((item) => accessCheck.push(item));
  // console.log(user, '🚀 ~ accessCheck', accessCheck);

  let isAdminCourse = accessCheck.includes(
    'Admin.Course'
  );
  // let isAdminReportCurriculum = accessCheck.includes(
  //   'Admin.Report.Curriculum'
  // );
  let [isAdminReportCurriculum, isAdminReportAuditException, isAdminReportInstance] = ['Admin.Report.Curriculum', 'Admin.Report.AuditException', 'Admin.Report.Instance'].map(permission => accessCheck.includes(permission));

  let isAdminUserManage = accessCheck.includes(
    'Admin.User.Manage'
  );
  let isAdminReportCurriculumManager = accessCheck.includes(
    'Admin.Report.Curriculum'
  );
  let isAdminTranslation = accessCheck.includes(
    'Admin.Translation'
  );
  let isAdminUserDealershipAccess = accessCheck.includes(
    'Admin.User.Dealership'
  );

  let LeaderbordAccessCheck = accessCheck.includes(
    'Recognition.Leaderboard.Access-xx'
  );
  let DashboardAccessCheck = accessCheck.includes(
    'Recognition.Dashboard.Access-xx'
  );

  let UserActivityAccessCheck = accessCheck.includes(
    'Report.Activity'
  );

  const nonLeaderboard = menuItems[0].items.filter(
    (item) => item.name !== 'Leaderboard'
  );
  const nonDashboard = menuItems[0].items.filter(
    (item) => item.name !== 'Dashboard'
  );

  const noLeaderboardMenuItems = [{ items: nonLeaderboard }];
  // console.log('🚀 ~ noLeaderboardMenuItems', noLeaderboardMenuItems);
  const noDashboardMenuItems = [{ items: nonDashboard }];
  // console.log('🚀 ~ noDashboardMenuItems', noDashboardMenuItems);

  let adminSideMenuItem = [];

  if (isAdminReportCurriculum || isAdminReportAuditException || isAdminReportInstance) {
    adminSideMenuItem.push({ name: textTranslation('Report Manager'), link: '/admin/training/options', icon: '' });
  }
  if (isAdminUserManage) {
    adminSideMenuItem.push({ name: textTranslation('User Access Manager'), link: '/admin/user/manager', icon: '' });
  }

  if (isAdminCourse) {
    adminSideMenuItem.push({ name: textTranslation('Courses'), link: '/admin/courses', icon: '' });
  }
  if (isAdminTranslation) {
    adminSideMenuItem.push({ name: textTranslation('Translation'), link: '/admin/translation/list' });
  }

  if (UserActivityAccessCheck) {
    adminSideMenuItem.push({ name: textTranslation('User Activity'), link: '/admin/user-activity' });
  }

  let adminSideMenu = {
    name: textTranslation('Admin'), link: '', icon: AppBlockingOutlinedIcon,
    items: adminSideMenuItem
  }
  let oldTrainingOld = [];

  // if (accessCheck.includes('Report.Dashboard.Admin')) {
  //   oldTrainingOld = [{
  //     name: 'Old Training Report Centre', link: '', icon: BarChartIcon
  //   }];
  // }


  const noDashboardAndLeaderboardMenuItems = [
    {
      items: oldTrainingOld,
    }
  ];

  if (accessCheck.includes('Report.Dashboard.Admin') || accessCheck.includes('Report.Dashboard.Manage') || accessCheck.includes('Report.Dashboard.Access')) {
    noDashboardAndLeaderboardMenuItems[0].items.push({
      name: 'Training Report Centre', link: 'ndashboard', icon: BarChartIcon,
    });
  }
  if (accessCheck.includes('Dealership.Training.Student') && !accessCheck.includes('Dealership.Training.Overview')) {
    noDashboardAndLeaderboardMenuItems[0].items.push({
      name: textTranslation('Dashboard'), link: '/student', icon: GridViewOutlinedIcon,
    });
  }
  if (isAdminReportCurriculum || isAdminCourse || isAdminTranslation) {
    noDashboardAndLeaderboardMenuItems[0].items.push(adminSideMenu);
  }

  if (accessCheck.includes('Dealership.Training.Overview') || accessCheck.includes('Report.Dashboard.Access') || accessCheck.includes('Report.Dashboard.Admin') || accessCheck.includes('Report.Dashboard.Manage')) {

    noDashboardAndLeaderboardMenuItems[0].items.push({
      name: textTranslation('Dealership Overview'), link: '/gm-dashboard', icon: ViewListOutlinedIcon,
    });

    if (user && user.have_training) {
      noDashboardAndLeaderboardMenuItems[0].items.push({
        name: textTranslation('Dashboard'), link: `/student/${reportDealershipData.id}`, icon: GridViewOutlinedIcon,
      });
    }
  }

  if (accessCheck.includes('Dealership.Training.Overview') || accessCheck.includes('Report.Dashboard.Access') || accessCheck.includes('Report.Dashboard.Manage') || accessCheck.includes('Report.Dashboard.Admin')) {
    noDashboardAndLeaderboardMenuItems[0].items.push({
      name: textTranslation('Gaps Report'), link: '/gaps-report', icon: InfoOutlinedIcon,
    });
  }
  const displayFilterMenuItems =
    LeaderbordAccessCheck === false && DashboardAccessCheck === false
      ? noDashboardAndLeaderboardMenuItems
      : LeaderbordAccessCheck === true && DashboardAccessCheck === true
        ? menuItems
        : LeaderbordAccessCheck === false && DashboardAccessCheck === true
          ? noLeaderboardMenuItems
          : LeaderbordAccessCheck === true && DashboardAccessCheck === false
            ? noDashboardMenuItems
            : null;

  // console.log('~ displayFilterMenuItems=>', displayFilterMenuItems);
  return (
    <>
      {displayFilterMenuItems.map((section) => (
        <MenuWrapper key={section?.items[0]?.name}>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                {t(section.heading)}
              </ListSubheader>
            }
          >
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname
            })}
          </List>
        </MenuWrapper>
      ))}
      <MenuWrapper>
        <List component="div" style={{ marginTop: '40px', marginLeft: '5px' }}>
          <LanguageSwitcher />
          <BackToHondaHub />
          <LogOut />
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
