export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

let apiUrl = 'http://localhost:3033';
switch (process.env.REACT_APP_ENV) {
  case 'local': {
    apiUrl = 'http://localhost:3033';
    break;
  }
  case 'dev': {
    apiUrl = 'https://training-report-center-backend-dev.hondahub.ca';
    break;
  }
  case 'prod': {
    apiUrl = 'https://training-report-center-backend.hondahub.ca';
    break;
  }
  default: {
    break;
  }
}

export const api = {
  url: apiUrl
};

// https://trc-dev.hondahub.ca/dashboard
// http://localhost:3000/dashboard
// https://hondahub.ca/
// https://dev.hondahub.ca/?r=saml2/idp/SSOService&spentityid=https://trc-dev.hondahub.ca
const url = document.URL;
// console.log('🚀 ~ url', url);

let backArrrowUrl = '';
let backArrowName = '';
let loginLabel = '';
let loginUrl = '';
let highFive = '';

if (url.includes('trc-dev.hondahub')) {
  backArrrowUrl = 'https://dev.hondahub.ca/';
  backArrowName = 'Honda';
  highFive = 'dev.hondahub';
  loginLabel = 'Dev Honda Hub';
  loginUrl =
    'https://dev.hondahub.ca/?r=saml2/idp/SSOService&spentityid=https://trc-dev.hondahub.ca';
}
if (url.includes('trc-dev.acurahub')) {
  backArrrowUrl = 'https://dev.acurahub.ca/';
  backArrowName = 'Acura';
  highFive = 'dev.acurahub';
  loginLabel = 'Dev Acura Hub';
  loginUrl =
    'https://dev.acurahub.ca/?r=saml2/idp/SSOService&spentityid=https://trc-dev.acurahub.ca';
}
if (url.includes('hondahub') && !url.includes('dev')) {
  backArrrowUrl = 'https://hondahub.ca/';
  backArrowName = 'Honda';
  highFive = 'hondahub';
  loginLabel = 'Honda Hub';
  loginUrl =
    'https://hondahub.ca/?r=saml2/idp/SSOService&spentityid=https://trc.hondahub.ca';
}
if (url.includes('acurahub') && !url.includes('dev')) {
  backArrrowUrl = 'https://acurahub.ca/';
  backArrowName = 'Acura';
  highFive = 'acurahub';
  loginLabel = 'Acura Hub';
  loginUrl =
    'https://acurahub.ca/?r=saml2/idp/SSOService&spentityid=https://trc.acurahub.ca';
}
if (url.includes('localhost')) {
  backArrrowUrl = 'https://dev.hondahub.ca/';
  backArrowName = 'Honda';
  highFive = 'dev.hondahub';
  loginLabel = 'Honda Hub';
  loginUrl =
    'https://dev.hondahub.ca/index.php?r=saml2/idp/SSOService&spentityid=localhost:3033';
}

export const backArrow = {
  url: backArrrowUrl,
  name: backArrowName
};
// console.log('🚀 ~ backArrow', backArrow);

export const loginDetails = {
  loginUrl,
  loginLabel
};
export const highFiveDetail = {
  highFive
};

// export loginDetails   ssoLoginUrl   goBackUrl  goBackLabel ssoLoginLabel
