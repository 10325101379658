// import { IconButton, useTheme } from '@mui/material';
import { useContext, useEffect } from 'react';
import PowerSettingsNewTwoToneIcon from '@mui/icons-material/PowerSettingsNewTwoTone';
import { useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import internationalization from 'src/i18n/i18n';
import './index.css';
import { TranslationContext } from 'src/contexts/TranslationContext';

function SidebarFooter() {
  // const theme = useTheme();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { textTranslation } = useContext(TranslationContext);
  const localStorageLng = localStorage.getItem('lng');
  useEffect(() => {
    internationalization.changeLanguage(localStorageLng);
  }, [localStorageLng]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      {/* <IconButton
        sx={{
          color: '#6F6E7A',
          backgroundColor: 'white',
          transition: `${theme.transitions.create(['all'])}`
        }}
        onClick={handleLogout}
      >
        <PowerSettingsNewTwoToneIcon fontSize="small" />
        <div style={{ fontSize: '17px', marginLeft: '5px' }}>Log Out</div>
      </IconButton> */}
      <button type="button" className="logOutButton" onClick={handleLogout}>
        <PowerSettingsNewTwoToneIcon fontSize="small" />
        <div
          style={{
            fontSize: '17px',
            marginLeft: '12px'
          }}
        >
          {textTranslation('Log Out')}
        </div>
      </button>
    </div>
  );
}

export default SidebarFooter;
