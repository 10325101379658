import BarChartIcon from '@mui/icons-material/BarChart';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';

const menuItems = [
  {
    items: [
      {
        name: 'Dashboard',
        icon: BarChartIcon,
        link: '/dashboard'
      },
      {
        name: 'About The Program',
        icon: CardTravelIcon,
        link: '/about'
      },
      {
        name: 'Leaderboard',
        icon: MilitaryTechIcon,
        link: '/leaderboard'
      }
    ]
  }
];

export default menuItems;
