import { Box, alpha, lighten, useTheme, IconButton } from '@mui/material';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
// import ThemeSettings from 'src/components/ThemeSettings';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';

import Sidebar from './Sidebar';
// import Header from './Header';
import { SidebarContext } from 'src/contexts/SidebarContext';

const ExtendedSidebarLayout = () => {
  const theme = useTheme();

  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <>
      <div className="toggleMenuWrapper">
        <IconButton onClick={toggleSidebar}>
          {!sidebarToggle ? (
            <MenuTwoToneIcon sx={{ fontSize: 30, color: 'grey' }} />
          ) : null}
        </IconButton>
      </div>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.05
                )}`
          }
        }}
      >
        {/* <Header /> */}

        <Sidebar />

        <Box
          sx={{
            position: '',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`
            }
          }}
        >
          {/* <Box display="block"> */}
          <Outlet />
          {/* </Box> */}
          {/* <ThemeSettings /> */}
        </Box>
      </Box>
    </>
  );
};

export default ExtendedSidebarLayout;
