import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const NoData = Loader(lazy(() => import('src/content/NoData')));

const NoDataRoute = [
  {
    path: '/',
    element: <NoData />
  }
];

export default NoDataRoute;
