import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  Drawer,
  // alpha,
  styled,
  Divider,
  useTheme
  // lighten,
  // darken
} from '@mui/material';
// import Logo from 'src/components/LogoSign';
import Logo from './logo/Honda_Institute_EN_black.png';
// import EngLogo from './logo/EngLogo.png';
import EngLogo from './logo/Honda_Institute_EN_black.png';
import FrLogo from './logo/Honda_Institute_FR_black.png';

// import FrLogo from './logo/FrLogo.png';
import AcuraEn from './logo/Guild_Sales_EN-1.png';
import AcuraFr from './logo/Guild_Sales_FR-1.png';
import EngLogo1 from './logo/img1.png';
import FrLogo1 from './logo/img2.png';
import SALogo from './logo/sa_logo.png';
import HondaServiceLogoEng from './logo/service_eng.png';
import HondaServiceLogoFr from './logo/service_fr.png';
import AcuraServiceEng from './logo/acura_service_eng.png';
import AcuraServiceFr from './logo/acura_service_fr.png';
import AcuraPartsServiceEng from './logo/acura_parts_eng.png';
import AcuraPartsServiceFr from './logo/acura_parts_fr.png';
import SidebarTopSection from './SidebarTopSection';
import SidebarMenu from './SidebarMenu';
import { TranslationProvider } from 'src/contexts/TranslationContext';

// import SidebarFooter from './SidebarFooter';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 10px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const { user } = useAuth();
  // console.log('🚀 ~ user', user);
  const { i18n } = useTranslation();
  const getLanguage = i18n.language;
  // console.log('🚀 ~ getLanguage', getLanguage);


  // console.log('In Translation..');

  let getPermissions = user?.permissions?.items;
  let getRights = localStorage.getItem('accessRights');

  // console.log('getPermissions ', getPermissions);

  if (getRights === null) {
    localStorage.setItem('accessRights', getPermissions);
  }

  const setLogo = (logo, lang) => {
    return logo === 'hde_cosl' && lang === 'en' ? (
      <img src={EngLogo} alt="logo" style={{ width: 125, height: 125 }} />
    ) : logo === 'hde_cosl' && lang === 'fr' ? (
      <img src={FrLogo} alt="logo" style={{ width: 125 }} />
    ) : logo === 'concierge_cosl' && lang === 'en' ? (
      <img src={AcuraEn} alt="logo" style={{ width: 200, height: 200 }} />
    ) : logo === 'concierge_cosl' && lang === 'fr' ? (
      <img src={AcuraFr} alt="logo" style={{ width: 250, height: 250 }} />
    ) : logo === 'hde_fi' && lang === 'en' ? (
      <img src={EngLogo1} alt="logo" style={{ width: 125, height: 125 }} />
    ) : logo === 'hde_fi' && lang === 'fr' ? (
      <img src={FrLogo1} alt="logo" style={{ width: 100, height: 125 }} />
    ) : logo === 'hde_sa' && lang === 'en' ? (
      <img src={SALogo} alt="logo" style={{ width: 125, height: 125 }} />
    ) : logo === 'hde_service' && lang === 'en' ? (
      <img
        src={HondaServiceLogoEng}
        alt="logoEng"
        style={{ width: 125 }}
      />
    ) : logo === 'hde_service' && lang === 'fr' ? (
      <img
        src={HondaServiceLogoFr}
        alt="logo"
        style={{ width: 125 }}
      />
    ) : logo === 'concierge_service' && lang === 'en' ? (
      <img
        src={AcuraServiceEng}
        alt="logo"
        style={{ width: 120, height: 100 }}
      />
    ) : logo === 'concierge_service' && lang === 'fr' ? (
      <img
        src={AcuraServiceFr}
        alt="logo"
        style={{ width: 120, height: 100 }}
      />
    ) : logo === 'concierge_parts' && lang === 'en' ? (
      <img
        src={AcuraPartsServiceEng}
        alt="logo"
        style={{ width: 120, height: 100 }}
      />
    ) : lang === 'fr' ? (
      <img
        src={FrLogo}
        alt="logo"
        style={{ width: 125 }}
      />
    ) : (
      <img src={Logo} style={{ width: 125 }} alt="logo" />
    );
  };

  return (
    <>
      <TranslationProvider>
        <SidebarWrapper
          sx={{
            width: '280px',
            display: {
              xs: 'none',
              lg: 'inline-block'
            },

            position: 'fixed',
            left: 0,
            top: 0,

            borderRadius: '0px 6px 6px 0px',
            backgroundColor: '#ffffff',
            boxShadow: '0px 4px 3px #CCCEDD, 3px 0px 4px rgba(239, 239, 239, 0.5)'
            // background:
            //   theme.palette.mode === 'dark'
            //     ? alpha(lighten(theme.header.background, 0.1), 0.5)
            //     : darken(theme.colors.alpha.black[100], 0.5),
            // boxShadow:
            //   theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
          }}
        >
          <Scrollbar>
            <Box
              mt={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {/* <Box
              mx={7}
              sx={{
                width: 123
              }}
            > */}
              {/* {user.jobTitle === 'Sales Person' && <img src={Logo} alt="logo" />}
            {user.jobTitle === 'F&I Manager' && (
              <img src={Logo2} alt="logo2" style={{ width: 100 }} />
            )}
            {user.jobTitle === 'Support' && (
              <img src={Logo1} alt="logo1" style={{ width: 100 }} />
            )} */}
              {/* </Box> */}
              {/* {getLanguage === 'en' && (
              <img
                src={EngLogo}
                alt="logoEng"
                style={{ width: 125, height: 125 }}
              />
            )}
            {getLanguage === 'fr' && (
              <img
                src={FrLogo}
                alt="logoFr"
                style={{ width: 100, height: 125 }}
              />
            )} */}
              {setLogo(user.logo, getLanguage)}
            </Box>
            <Divider
              sx={{
                my: theme.spacing(1),
                mx: theme.spacing(1),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />
            <SidebarTopSection />
            <Divider
              sx={{
                my: theme.spacing(1),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />
            <TranslationProvider>
              <SidebarMenu />
            </TranslationProvider>

          </Scrollbar>
          {/* <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10]
          }}
        /> */}
          {/* <SidebarFooter /> */}
          {/* <p style={{ color: 'black' }}>Yusuf</p> */}
        </SidebarWrapper>
        <Drawer
          sx={{
            boxShadow: `${theme.sidebar.boxShadow}`
          }}
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper
            sx={{
              backgroundColor: 'white'
              // background:
              //   theme.palette.mode === 'white'
              //     ? theme.colors.alpha.white[100]
              //     : darken(theme.colors.alpha.black[100], 0.5)
            }}
          >
            <Scrollbar>
              {/* <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52
                }}
              >
                <Logo />
              </Box>
            </Box> */}
              <Divider
                sx={{
                  my: theme.spacing(3),
                  mx: theme.spacing(2),
                  background: theme.colors.alpha.trueWhite[10]
                }}
              />
              <SidebarTopSection />
              <Divider
                sx={{
                  my: theme.spacing(3),
                  mx: theme.spacing(2),
                  background: theme.colors.alpha.trueWhite[10]
                }}
              />
              <SidebarMenu />
            </Scrollbar>
            {/* <SidebarFooter /> */}
          </SidebarWrapper>
        </Drawer>
      </TranslationProvider>
    </>
  );
}

export default Sidebar;
